import React, { useState, useEffect } from 'react'
import Base from "../../core/Base"
import { disAuthenticated } from "../../auth/helper/driverIndex"
import { Link, Redirect } from "react-router-dom"
import { createlogsheet, getpartners, gettrucks, gettrailers, getdriver } from "../helper/driverapicall"
import LoadingScreen from '../../core/helper/LoadingScreen';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import ImageUploaderWithCropper from '../../core/helper/ImageCropper/ImageUploaderWithCropper';
import { ENABLE_IMAGE_CROPPING } from '../../backend';

export default function AddLogSheet() {

   // Kill switch based implementation for cropper image
   const image_cropper_enabled_prop = (ENABLE_IMAGE_CROPPING).toString().toLowerCase();
   const isImageCropperEnabled = image_cropper_enabled_prop==="true" ? true : false;

  const { driver, token } = disAuthenticated();

  const [values, setValues] = useState({
    truckNo: "",
    otherTruckNo: "",
    trailerNo: "",
    otherTrailerNo: "",
    date: moment().format('YYYY-MM-DD'),
    timeIn: "",
    startOdometer: "",
    reimbursement: false,
    nightOut: false,
    nightOutPurpose: "",
    pickUpCity: "",
    ratePerHour: "",
    otherReimbursementCompany: "",
    reimbursementCompany: "",
    reimbursementDate: "",
    reimbursementPurpose: "",
    reimbursementLocation: "",
    reimbursementAmount: "",
    photo1: "",
    photo2: "",
    photo3: "",
    photo4: "",
    pickup: "",
    partners: "",
    trucks: "",
    trailers: "",
    loading: false,
    isLoaded: "",
    error: "",
    CreatedLogSheet: "",
    getaRedirect: false,
    formData: "",
    didRedirect: false
  });

  const ref1 = React.useRef();
  const ref2 = React.useRef();
  const [image1Uploaded, setImage1Uploaded] = useState(false);
  const [image2Uploaded, setImage2Uploaded] = useState(false);

  const { truckNo, otherTruckNo, trailerNo, otherTrailerNo, date, timeIn, pickUpCity, startOdometer, photo1, photo2, photo3, photo4, partners, trucks, trailers,
    reimbursement, nightOut, nightOutPurpose, ratePerHour, otherReimbursementCompany, reimbursementCompany, reimbursementDate, reimbursementPurpose, reimbursementLocation, reimbursementAmount, loading, isLoaded, error, CreatedLogSheet, getaRedirect, formData, didRedirect } = values;

  //Method to preload Trucks, Trailers and Partners
  const preload = () => {
    setValues({ ...values, loading: true, isLoaded: ""})
    getpartners(driver._id, token).then(data1 => {
      if (data1.error) {
        setValues({ ...values, error: data1.error,  loading: "", isLoaded: ""});
      } else {
        gettrucks(driver._id, token).then(data2 => {
          if (data2.error) {
            setValues({ ...values, error: data2.error,  loading: "", isLoaded: ""});
          } else {
            gettrailers(driver._id, token).then(data3 => {
              if (data3.error) {
                setValues({ ...values, error: data3.error,  loading: "", isLoaded: ""});
              } else {
                getdriver(driver._id, token).then(data4 => {
                  if (data4.error) {
                    setValues({ ...values, error: data4.error,  loading: "", isLoaded: ""});
                  } else {
                    setValues({ ...values, 
                      partners: data1, trucks: data2, trailers: data3,
                      ratePerHour: data4.ratePerHour ? data4.ratePerHour : "", 
                      loading: "", isLoaded: true, formData: new FormData() });
                  }
                })
                .catch(err => {
                  console.log('Error loading the time card form:', err);
                  setValues({ ...values, error: "Error loading the time card form", loading: "" });
                  toastifyMessage("error", 'Error loading the time card form');
                });
              }
            })
            .catch(err => {
              console.log('Error loading the time card form:', err);
              setValues({ ...values, error: "Error loading the time card form", loading: "" });
              toastifyMessage("error", 'Error loading the time card form');
            });
          }
        })
        .catch(err => {
          console.log('Error loading the time card form:', err);
          setValues({ ...values, error: "Error loading the time card form", loading: "" });
          toastifyMessage("error", 'Error loading the time card form');
        });
      }
    })
    .catch(err => {
      console.log('Error loading the time card form:', err);
      setValues({ ...values, error: "Error loading the time card form", loading: "" });
      toastifyMessage("error", 'Error loading the time card form');
    });
  }

  useEffect(() => {
    preload();
  }, [])



  const successMessage = () => (
    <div className="alert alert-success mt-3"
      style={{ display: CreatedLogSheet ? "" : "none" }}
    >
      <h4>{CreatedLogSheet}</h4>
    </div>
  )

  const warningMessage = () => (
    <div className="alert alert-danger mt-3"
      style={{ display: error ? "" : "none" }}
    >
      <h4> {error} </h4>
    </div>
  )
      
  const performRedirect = () => {
    if (didRedirect) {
        if (driver) {
            return (
                <Redirect to="/driver/dashboard" />
            )
        }
    }
}

  const onSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: "", loading: true, CreatedLogSheet: "" });

    // Field Validation
    const requiredFields = [truckNo, trailerNo, date, timeIn, ((startOdometer || startOdometer===0)?startOdometer.toString():startOdometer), pickUpCity];
    const isReimbursementFilled = reimbursementDate && reimbursementPurpose && reimbursementLocation && reimbursementAmount;
    if (
        requiredFields.some(field => !field) ||
        (reimbursement && (reimbursement===true || reimbursement==="true") && !isReimbursementFilled) ||
        reimbursement === '' ||
        nightOut === '') {
        setValues({ ...values, error: 'All the fields marked with * are mandatory', loading:"",  CreatedLogSheet:"" });
        toastifyMessage("warning", 'All the fields marked with * are mandatory');
        return;
    }
    
    if(truckNo === "other" && (otherTruckNo === "")){ 
      setValues({ ...values, error: 'Enter valid truck number', loading: "", CreatedLogSheet: '' });
      toastifyMessage("warning", 'Enter valid truck number');
      return;
    }

    if(trailerNo === "other" && (otherTrailerNo === "")){ 
      setValues({ ...values, error: 'Enter valid trailer number', loading: "", CreatedLogSheet: '' });
      toastifyMessage("warning", 'Enter valid trailer number');
      return;
    }

    // Set the default fields in the form
    if (!formData.get('date')) {
      formData.set('date', date)
    }

    if (!formData.get('ratePerHour')) {
      formData.set('ratePerHour', ratePerHour)
    }

    if (!formData.get('reimbursement')) {
      formData.set('reimbursement', reimbursement)
    }

    if (!formData.get('nightOut')) {
      formData.set('nightOut', nightOut)
    }

    // Set the driver for the time sheet
    formData.set('driverNo', driver._id)

    createlogsheet(driver._id, formData, token)
      .then(data => {
        if (data.error) {
          setValues({ ...values, error: data.error, loading: "", CreatedLogSheet: "" })
          toastifyMessage("error", data.error);
        } else {
          setValues({
            ...values,
            truckNo: "",
            otherTruckNo: "",
            trailerNo: "",
            otherTrailerNo: "",
            date: "",
            timeIn: "",
            timeOut: "",
            downTime: "",
            startOdometer: "",
            endOdometer: "",
            reimbursement: false,
            nightOut: false,
            nightOutPurpose: "",
            pickUpCity: "",
            reimbursementDate: "",
            reimbursementPurpose: "",
            reimbursementLocation: "",
            reimbursementAmount: "",
            photo1: "",
            photo2: "",
            photo3: "",
            photo4: "",
            pickup: "",
            loading: "",
            error: "",
            CreatedLogSheet: "Time card created successfully",
            formData: new FormData(),
            didRedirect: true
          })

          // Clear the cropper state
          resetCropper();
      
            setTimeout(() => {
              toastifyMessage("success", "Time card created successfully");
            })
        }
        
      })
      .catch(err => {
        console.log('Error creating the time card:', err);
        setValues({ ...values, error: "Error creating the time card", loading: "", CreatedLogSheet: "" });
        toastifyMessage("error", 'Error creating the time card');
    });
  }

  const resetCropper = () => {
    if(isImageCropperEnabled){
        setImage1Uploaded(false);
        setImage2Uploaded(false);
    }
};

const handleSaveImage = (name, file) => {
  const value = ((name === "photo1") || (name === "photo2")) ? file : "";
  setValues({ ...values, [name]: value })
  formData.set(name, value) 
};

const handleRemoveImage = (name) => {
  setValues({ ...values, [name]: "" })
  if(formData.get(name)){
      formData.delete(name);
  }
  if(name=="photo1"){
      ref1.current.value = "";
      setImage1Uploaded(false);
  }
  if(name=="photo2"){
      ref2.current.value = "";
      setImage2Uploaded(false);
  }
};

  const handleChange = name => event => {
    const value = ((name === "photo1") || (name === "photo2") || (name === "photo3") || (name === "photo4")) ? event.target.files[0] : event.target.value
    
    if ((name === "reimbursement") && (value === "false" || value === false)) {
      setValues({
        ...values,
        reimbursementDate: "",
        reimbursementPurpose: "",
        reimbursementLocation: "",
        reimbursementAmount: "",
        [name]: value
      })
      formData.delete("reimbursementDate")
      formData.delete("reimbursementPurpose")
      formData.delete("reimbursementLocation")
      formData.delete("reimbursementAmount")
      formData.set(name, value)
    }

    else if ((name === "nightOut") && (value === "false" || value === false)) {
      setValues({
        ...values,
        nightOutPurpose: "",
        [name]: value
      })
      formData.delete("nightOutPurpose")
      formData.set(name, value)
    }

    else if(((name === "trailerNo") || (name === "truckNo")) && (value !== "other")){
      if(name==="trailerNo"){
          setValues({ ...values, [name]: value, otherTrailerNo: "" })
          formData.set(name, value)
          if(formData.get("otherTrailerNo") || formData.get("otherTrailerNo")===""){
              formData.delete("otherTrailerNo")
          }
      }

      if(name==="truckNo"){
          setValues({ ...values, [name]: value, otherTruckNo: "" })
          formData.set(name, value)
          if(formData.get("otherTruckNo") || formData.get("otherTruckNo")===""){
              formData.delete("otherTruckNo")
          }
      }

  }
  
  else{
      setValues({ ...values, [name]: value })
      formData.set(name, value)
  }
  };



  const createLogSheetForm = () => (
    <form className="mt-4" style={{ display: (trucks != "" || trailers != "" || partners != "") ? "" : "none" }}>

      <div className="form-group">
        <label className="text-light"> Truck <span className="text-warning">*</span></label>
        <select
          onChange={handleChange("truckNo")}
          className="form-control"
          placeholder="Truck"
        >
          <option value="" selected={truckNo===""}>Select the Truck</option>
          {trucks && trucks.map((truck, index) => {
            return (
              <option key={index} value={truck._id}>
                {truck.truckNumber}
              </option>
            )
          })}
          <option value="other" selected={truckNo==="other"}>Other</option>
        </select>
      </div>

      <div className="form-group" style={{ display: ( truckNo && (truckNo === "other")) ? "" : "none" }}>
                <label className="text-light">Other Truck <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherTruckNo')}
                    className="form-control"
                    placeholder="Truck Number"
                    value={otherTruckNo} />
      </div>

      <div className="form-group">
        <label className="text-light"> Trailer <span className="text-warning">*</span></label>
        <select
          onChange={handleChange("trailerNo")}
          className="form-control"
          placeholder="Trailer"
        >
          <option value="" selected={trailerNo===""}>Select the Trailer</option>
          {trailers && trailers.map((trailer, index) => {
            return (
              <option key={index} value={trailer._id}>
                {trailer.trailerNumber}
              </option>
            )
          })}
          <option value="other" selected={trailerNo==="other"}>Other</option>
        </select>
      </div>

      <div className="form-group" style={{ display: ( trailerNo && (trailerNo === "other")) ? "" : "none" }}>
                <label className="text-light">Other Trailer <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherTrailerNo')}
                    className="form-control"
                    placeholder="Trailer Number"
                    value={otherTrailerNo} />
      </div>

      <div className="form-group">
        <label className="text-light"> Start City <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("pickUpCity")}
          name="pickUpCity"
          className="form-control"
          placeholder="Pickup City"
          value={pickUpCity}
        />
      </div>

      <div className="form-group">
        <label className="text-light"> Date <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("date")}
          type="date"
          name="date"
          className="form-control"
          value={date}
        />
      </div>

      <div className="form-group">
        <label className="text-light"> Time In  <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("timeIn")}
          type="time"
          name="timeIn"
          className="form-control"
          value={timeIn}
        />
      </div>

      <div className="form-group">
        <label className="text-light"> Start Odometer <span className="text-warning">*</span></label>
        <input
          type="number"
          onChange={handleChange("startOdometer")}
          name="startOdometer"
          className="form-control"
          placeholder="Ex. 30000"
          value={startOdometer}
        />
      </div>

      <div className="form-group">
        <label className="text-light"> Night out </label>
        <select
          onChange={handleChange("nightOut")}
          className="form-control"
        >
          <option value={false} selected={nightOut===false}>No</option>
          <option value={true}>Yes</option>
        </select>
      </div>

      <div className="form-group" style={{ display: ( nightOut && (nightOut === true || nightOut === "true")) ? "" : "none" }}>
        <label className="text-light"> Night out Purpose </label>
        <textarea
          onChange={handleChange("nightOutPurpose")}
          name="nightOutPurpose"
          className="form-control"
          placeholder="Purpose of Night out"
          value={nightOutPurpose}
        />
      </div>

      <div className="form-group">
        <label className="text-light"> Reimbursement </label>
        <select
          onChange={handleChange("reimbursement")}
          className="form-control"
        >
          <option value={false} selected={reimbursement===false}>No</option>
          <option value={true}>Yes</option>
        </select>
      </div>

      <div className="form-group" style={{ display: ( reimbursement && (reimbursement === true || reimbursement === "true")) ? "" : "none" }}>
        <label className="text-light"> Reimbursement Date <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("reimbursementDate")}
          type="date"
          name="reimbursementDate"
          className="form-control"
          value={reimbursementDate}
        />
      </div>

      <div className="form-group" style={{ display: ( reimbursement && (reimbursement === true || reimbursement === "true")) ? "" : "none" }}>
        <label className="text-light"> Reimbursement Location <span className="text-warning">*</span></label>
        <input
          onChange={handleChange("reimbursementLocation")}
          name="reimbursementLocation"
          className="form-control"
          placeholder="Ex. Brampton, ON"
          value={reimbursementLocation}
        />
      </div>

      <div className="form-group" style={{ display: ( reimbursement && (reimbursement === true || reimbursement === "true")) ? "" : "none" }}>
        <label className="text-light"> Reimbursement Purpose <span className="text-warning">*</span></label>
        <textarea
          onChange={handleChange("reimbursementPurpose")}
          name="reimbursementPurpose"
          className="form-control"
          placeholder="Purpose of reimbursement"
          value={reimbursementPurpose}
        />
      </div>

      <div className="form-group" style={{ display: ( reimbursement && (reimbursement === true || reimbursement === "true")) ? "" : "none" }}>
        <label className="text-light"> Reimbursement Amount <span className="text-warning">*</span></label>
        <input
          type="number"
          onChange={handleChange("reimbursementAmount")}
          name="reimbursementAmount"
          className="form-control"
          placeholder="Ex. 150"
          value={reimbursementAmount}
        />
      </div>

      <span className="text-white"> Trip Photos </span>
      {isImageCropperEnabled ? 
                    <>
                        <ImageUploaderWithCropper fileInputRef={ref1}  onSave={handleSaveImage} onRemove={handleRemoveImage} photoFieldName="photo1" imageUploaded={image1Uploaded} setImageUploaded={setImage1Uploaded} />
                        <ImageUploaderWithCropper fileInputRef={ref2} onSave={handleSaveImage} onRemove={handleRemoveImage} photoFieldName="photo2" imageUploaded={image2Uploaded} setImageUploaded={setImage2Uploaded}/>
                    </>
                 :
                    <>
                        <div className="form-group">
                          <label className="btn btn-block btn-success rounded">
                            <input
                              onChange={handleChange("photo1")}
                              type="file"
                              name="photo1"
                              accept="image/*"
                              placeholder="Choose an Image for the Details"
                            />
                          </label>
                        </div>
                        <div className="form-group">
                          <label className="btn btn-block btn-success rounded">
                            <input
                              onChange={handleChange("photo2")}
                              type="file"
                              name="photo2"
                              accept="image/*"
                              placeholder="Choose an Image for the Details"
                            />
                          </label>
                        </div>
                    </>
      }
      
      <button type="submit" onClick={onSubmit} className="btn btn-outline-success mb-4 mt-3">
        Submit Time Card
      </button>
    </form>
  );

  return (
    <Base
      title="Time Card dashboard"
      description="Add time card"
      className="container bg-success p-4"
    >

      <Link to="/driver/dashboard" className="btn brn-md btn-dark mb-3">
        Driver Home
      </Link>

      <div className="row bg-dark test-white rounded center">
        <div className="col-md-8 offset-md-1 col-10 offset-1 ">
          {isLoaded && createLogSheetForm()}
          {performRedirect()}
          {successMessage()}
          {warningMessage()}
          {LoadingScreen(loading)}
          <ToastifyMessage/> 
        </div>
      </div>

    </Base>
  )
}