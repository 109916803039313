import React, { useState, useEffect } from 'react';
import Base from '../../core/Base';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { getalltripcards, getdrivers, deletelogsheet, fetchImageFromS3 } from '../helper/adminapicall';
import LoadingScreen from '../../core/helper/LoadingScreen';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import LogSheetTableView from '../../core/helper/LogSheetTableView';
import PreparingDownload from '../../core/helper/PreparingDownload';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import '../AdminSideSheet.css';
import { getTruckNo, getTrailerNo, getDate, getDayOfTheWeek, getBillableTotalHours, getLoadsSubTotal, getSheetDriverName } from '../../core/helper/helperFunctions';
import { S3_INTEGRATION_ENABLED, ENABLE_MONGO_READ_IMAGE, ENABLE_TEXTDROPDOWN_FILTER } from "../../backend";
import { fetchAndCacheImage, clearImageCache } from '../../core/helper/Utils/DownloadUtils';
import FilterDropdown from '../../core/helper/FilterWithDropdown/FilterDropdown';

export default function ViewTimeCards() {
    const { admin, token } = isAuthenticated();

    // Kill switch based implementation for S3
    const s3_integration_enabled_prop = (S3_INTEGRATION_ENABLED).toString().toLowerCase();
    const isS3IntegrationEnabled = s3_integration_enabled_prop==="true" ? true : false;

    // Kill switch to disable images reads from mongo database
    const enable_mongo_read_image_prop = (ENABLE_MONGO_READ_IMAGE).toString().toLowerCase();
    const isMongoDBImageReadEnabled = enable_mongo_read_image_prop==="true" ? true : false;

    // Kill switch based implementation for text based dropdown filters
    const enable_text_dropdown_filter_prop = (ENABLE_TEXTDROPDOWN_FILTER).toString().toLowerCase();
    const isTextDropdownFilterEnabled = enable_text_dropdown_filter_prop==="true" ? true : false;

    const [values, setValues] = useState({
        startDate: moment().subtract(5, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loading: "",
        loadingModal: "",
        error: "",
        isLoaded: "",
        logSheets: [],
        driverOptions: [],
        isReimbursementRequired: false
    });

    // Filter state
    const [filters, setFilters] = useState({
        driver: '',
        isTripCardVerified: '',
    });
    const [filteredSheets, setFilteredSheets] = useState([]);

    const { startDate, endDate, loading, loadingModal, error, isLoaded, logSheets, driverOptions, isReimbursementRequired } = values;
    let reimbursementCounter = false;

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: "" });
        getalltripcards(admin._id, { startDate, endDate }, token).then(data1 => {
            if (data1.error) {
                setValues({ ...values, error: data1.error, loading: "", isLoaded: "" });
                toastifyMessage("error", 'Error fetching the time cards');
            } else {
                getdrivers(admin._id, token).then(data2 => {
                    if (data2.error) {
                        setValues({ ...values, error: data2.error, loading: "", isLoaded: "" })
                    } else {
                        for (let i = 0; i < data1.length; i++) {
                            if (data1[i].reimbursementLocation && data1[i].reimbursementDate) {
                                reimbursementCounter = true;
                                break;
                            }
                        }
                        const updatedValues = {
                            ...values,
                            logSheets: data1,
                            loading: "",
                            isLoaded: true,
                            isReimbursementRequired: reimbursementCounter,
                            driverOptions: data2.map((driver) => ({
                                value: `${driver.firstName} ${driver.lastName}`,
                                label: `${driver.firstName} ${driver.lastName}`,
                              }))
                        };
                        setValues(updatedValues);
                        if(data1.length===0){
                            toastifyMessage("warning", 'No time card found');
                         }
                    }
                })
                .catch(err => {
                    console.log('Error loading the time cards:', err);
                    setValues({ ...values, error: "Error loading the time cards", loading: "" });
                    toastifyMessage("error", 'Error loading the time cards');
                });
            }
        })
        .catch(err => {
            console.log('Error loading the time cards:', err);
            setValues({ ...values, error: "Error loading the time cards", loading: "" });
            toastifyMessage("error", 'Error loading the time cards');
        });
    };

    const flipSheetStatus = (id, status) => {
        // Update the logSheets array in the state
        setValues(prevValues => ({
           ...prevValues,
           logSheets: prevValues.logSheets.map(
               sheet => sheet._id === id ? { ...sheet, isTripCardVerified: status } : sheet
           )
       }));
   }

    useEffect(() => {
        preload();
    }, []);

    // Updates the filtered list whenever the time cards are updated
    useEffect(() => {
        const filtered = logSheets.filter((sheet) => {
            return (
              (!filters.driver || ((sheet.driverNo && (sheet.driverNo[0].firstName + ' ' + sheet.driverNo[0].lastName)).toLowerCase().includes(filters.driver.toLowerCase()))) &&
              (!filters.isTripCardVerified || ((sheet.isTripCardVerified === false)))
            );
          });
        setFilteredSheets(filtered);
        
    }, [values.logSheets, filters]);

    // Handle filter search for driver
    const handleDropDownFilterChange = (name, selectedOption) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: selectedOption ? selectedOption.value : '',
        }));
    };

    // Handle search for time cards
    const handleTimeCardSearch = (event) => {
        const name = event.target.name;
        const value = name === 'isTripCardVerified' ? event.target.checked : event.target.value;
        setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: value,
        }));
    };

    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, [name]: value });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        preload();
    };

    const downloadPDF = () => {
        const doc = new jsPDF('landscape');
        const systemDate = new Date();
        const pageHeight = doc.internal.pageSize.getHeight();

        // Add content above the table
        doc.setFontSize(15);
        doc.setFont('helvetica', 'bold');
        doc.text(`Time Card`, doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });

        doc.setFontSize(11);
        doc.setFont('helvetica', 'bold');
        doc.text(`Date:`, 10, 18);
        doc.text(`Report Duration:`, 10, 23);

        doc.setFont('helvetica', 'normal');
        doc.text(`${moment(systemDate).format('MMMM D, YYYY')}`, 22, 18);
        doc.text(`${moment(startDate).format('MMMM D, YYYY')} to ${moment(endDate).format('MMMM D, YYYY')}`, 43, 23);

        // Define table columns based on LogSheetTableView component
        const tableColumns = [
            'Sr. No.', 'Date', 'Day', 'Driver', 'Truck', 'Trailer', 'Start City', 'Finish City', 'Start Odometer', 'End Odometer', 'Down time', 'Billable Start Time', 'Billable End Time', 'Deduction (in hour)', 'Notes', 'Billable Total Hours', 'Rate (in $)', '# of Loads Delivered', 'Night Out', 'Night out Subtotal', 'Grand Subtotal', 'Reimbursement'
        ];
        if (isReimbursementRequired) {
            tableColumns.push('Reimbursement Date', 'Reimbursement Purpose', 'Reimbursement Location', 'Reimbursement Amount');
        }

        // Define table rows based on logSheets data
        const tableRows = logSheets.map((logSheet, index) => {
            const row = [
                index + 1,
                logSheet.date ? getDate(logSheet.date) : "",
                getDayOfTheWeek(logSheet),
                getSheetDriverName(logSheet, true) || "",
                getTruckNo(logSheet, true),
                getTrailerNo(logSheet, true),
                logSheet.pickUpCity || "",
                logSheet.deliveryCity || "",
                logSheet.startOdometer || "",
                logSheet.endOdometer || "",
                (logSheet.downTime || logSheet.downTime === 0) ? logSheet.downTime : "",
                logSheet.timeIn || "",
                logSheet.timeOut || "",
                (logSheet.deductions || logSheet.deductions === 0) ? logSheet.deductions : "",
                (logSheet.notes? 
                    (logSheet.deductionNotes ? 
                        (logSheet.notes + "\n" + logSheet.deductionNotes):
                        logSheet.notes
                    ) : 
                    (
                        logSheet.deductionNotes?logSheet.deductionNotes:"-"
                    )
                ),
                getBillableTotalHours(logSheet),
                logSheet.ratePerHour ? ('$' + logSheet.ratePerHour.toFixed(2)) : "-",
                logSheet.numberOfLoads || "-",
                logSheet.nightOut ? (logSheet.nightOutPurpose ? ('Yes\n' + logSheet.nightOutPurpose) : 'Yes'): 'No',
                (logSheet.nightOut && logSheet.nightOutRate) ? ('$' + logSheet.nightOutRate.toFixed(2)) : "-",
                getLoadsSubTotal(logSheet, true),
                logSheet.reimbursement ? 'Yes' : 'No'
            ];
            if (isReimbursementRequired) {
                row.push(
                    logSheet.reimbursement && logSheet.reimbursementDate ? getDate(logSheet.reimbursementDate) : "-",
                    logSheet.reimbursement && logSheet.reimbursementPurpose ? logSheet.reimbursementPurpose : "-",
                    logSheet.reimbursement && logSheet.reimbursementLocation ? logSheet.reimbursementLocation : "-",
                    logSheet.reimbursement && (logSheet.reimbursementAmount || logSheet.reimbursementAmount === 0) ? logSheet.reimbursementAmount : "-"
                );
            }

            return row;
        });

        doc.autoTable({
            startY: 35,
            head: [tableColumns],
            body: tableRows,
            theme: 'striped',
            styles: {
                fontSize: 5,
                lineColor: [0, 0, 0],  // Border color
                lineWidth: 0.2         // Border width
            },
           
            tableLineColor: [0, 0, 0],  // Outer border color
            tableLineWidth: 0.2         // Outer border width
        });

        const filename = `Time_card_${moment(startDate).format('DDMMYYYY')}_${moment(endDate).format('DDMMYYYY')}.pdf`;
        doc.save(filename);
    };

    const downloadImagesPDF = async () => {
        setValues({ ...values, loadingModal: true });
        handleShowLoadingModal();
    
        const doc = new jsPDF('landscape');
        let startY = 5;
        let hasImage = false;
        let countOfImages = 0;
        
        // Fetch S3 images
        const fetchImagePromises = [];
        const imagesData = [];
        for (const [index, logSheet] of logSheets.entries()) {
            if (isS3IntegrationEnabled) {
                const imageFields = ['photo1Name', 'photo2Name', 'photo3Name', 'photo4Name'];
                imageFields.forEach((field, i) => {
                    if (logSheet && logSheet[field]) {
                        const promise = fetchImageFromS3(logSheet._id, `logPhoto${i + 1}`).then(async (url) => {
                            const data = await fetchAndCacheImage(url);
                            imagesData.push({data, timecard: index+1, count: i+1});
                        });
                        fetchImagePromises.push(promise);
                    }
                });
            }
    
            // Fetch MongoDB images
            if (isMongoDBImageReadEnabled) {
                for (let i = 1; i <= 4; i++) {
                    const photo = logSheet[`photo${i}`];
                    if (photo && photo.data) {
                        const imgData = `data:${photo.contentType};base64,${photo.data.toString('base64')}`;
                        imagesData.push({data: imgData, timecard: index+1, count: i+1});
                    }
                }
            }
        }
    
        await Promise.all(fetchImagePromises);
    
        // Add images to the PDF
        if (imagesData.length > 0) {
            hasImage = true;
            countOfImages += imagesData.length;

            // Group images by time card and sort them by time card and count
            const groupedImages = imagesData.reduce((acc, imageData) => {
                const { timecard, count } = imageData;
                if (!acc[timecard]) {
                    acc[timecard] = [];
                }
                acc[timecard].push(imageData);
                return acc;
            }, {});

            // Sort the grouped images by time card number
            const sortedTimeCards = Object.keys(groupedImages).sort((a, b) => a - b);

            for (const timeCard of sortedTimeCards) {
                const images = groupedImages[timeCard];
                // Sort images within each time card by count
                images.sort((a, b) => a.count - b.count);
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text(`Time Card ${timeCard}`, 10, startY);
                startY += 5;

                for (const [j, imageData] of images.entries()) {
                    const imgProps = doc.getImageProperties(imageData.data);
                    const pageWidth = doc.internal.pageSize.getWidth();
                    const pageHeight = doc.internal.pageSize.getHeight();
                    const imgMaxWidth = pageWidth * 0.88;
                    const imgMaxHeight = pageHeight * 0.88;

                    let imgWidth = imgMaxWidth;
                    let imgHeight = (imgProps.height * imgWidth) / imgProps.width;

                    if (imgHeight > imgMaxHeight) {
                        imgHeight = imgMaxHeight;
                        imgWidth = (imgProps.width * imgHeight) / imgProps.height;
                    }

                    const xPosition = (pageWidth - imgWidth) / 2;
                    const yPosition = startY + ((pageHeight - startY - imgHeight) / 2);

                    doc.text(`Image: ${j+1}`, 10, startY);
                    doc.addImage(imageData.data, 'JPEG', xPosition, yPosition, imgWidth, imgHeight);

                    doc.addPage();
                    startY = 5;
                }
            }
        }
    
        const lastPageNumber = doc.internal.getNumberOfPages();
        if (lastPageNumber > countOfImages) {
            doc.deletePage(doc.internal.getNumberOfPages());
        }
    
        hideLoadingModal();
        setValues({ ...values, loadingModal: false });
    
        if (!hasImage) {
            toastifyMessage("warning", "No images exist for the time card");
            return;
        }
    
        const filename = `Timecard_photos_${moment(startDate).format('DDMMYYYY')}_${moment(endDate).format('DDMMYYYY')}.pdf`;
        doc.save(filename);

        // Clear Image cache
        clearImageCache();
    };

    const dateFilterForm = () => (
        <div className="date-filter-form">
            <div className="date-filter-row">
                <label className="text-light">Start Date</label>
                <input
                    onChange={handleChange('startDate')}
                    type="date"
                    className="form-control date-input"
                    value={startDate}
                    max={endDate}
                />
                <label className="text-light">End Date</label>
                <input
                    onChange={handleChange('endDate')}
                    type="date"
                    className="form-control date-input"
                    value={endDate}
                    min={startDate}
                />
                <button onClick={onSubmit} className="btn btn-outline-info submit-btn rounded">
                    Submit
                </button>
                <button
                    onClick={downloadPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Sheet
                </button>
                <button
                    onClick={downloadImagesPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Photos
                </button>
            </div>
        </div>
    );

    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the time card?");
    const [deleteLogSheetId, setDeleteLogSheetId] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false);
    const handleShowDeleteMessage = () => setShowDeleteMessage(true);

    // Delete time card using the Admin privilege
    const deleteThisLogSheet = (logSheetId) => {
        setValues({ ...values, loadingModal: true, error: ""});
        deletelogsheet(logSheetId, admin._id, token)
        .then(data=> {
            if(data.error){
                setValues({ ...values, error: data, loadingModal: ""})
                toastifyMessage("error", 'Error deleting the time card');
            }
            else{
                toastifyMessage("success", 'Time Card deleted successfully');

                // To close the modal & reload the values
                handleCancelDeletion();
                setValues({ ...values, error: "", loadingModal: "", logSheets: (logSheets.filter(sheet => (sheet._id !== logSheetId)))})
            }
        })
        .catch(err => {
            console.log('Error deleting the time card:', err);
            setValues({ ...values, error: "Error deleting the time card", loadingModal: "" });
            toastifyMessage("error", 'Error deleting the time card');
        });
    }

    // For loading modal
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const hideLoadingModal = () => setShowLoadingModal(false);
    const handleShowLoadingModal = () => setShowLoadingModal(true);

    return (
        <Base title="Time Card Dashboard" description="View/Edit/Delete">
            <Link className="btn btn-info rounded" to={`/admin/dashboard`}>
                <span className="">Admin Home</span>
            </Link>
            <div className="log-dashboard">
                <div className="log-header">
                <h2 className="text-center text-success my-3 mt-5 mb-5" style={{ fontFamily: 'Englebert' }}>View Time Cards</h2>
                    {dateFilterForm()}
                </div>

                <div className="invoice-content">
                    {error && <h4 className="error-message">Error fetching the time cards</h4>}
                    {isLoaded && !error && logSheets.length > 0 && (
                        <>
                            <div className="filters">
                                {isTextDropdownFilterEnabled ?
                                        <div className="filter-item">
                                            <FilterDropdown
                                                label={"Filter the time cards using driver name"}
                                                placeholder={"Driver Name"}
                                                options={driverOptions}
                                                value={driverOptions.find((option) => option.value === filters.driver)}
                                                onChange={(selectedOption) => handleDropDownFilterChange('driver', selectedOption)}
                                            />
                                        </div>
                                    :
                                        <div className="filter-item">
                                            <p style={{ marginBottom: '10px' }}>Filter using Driver:</p>
                                            <input
                                                type="text"
                                                style={{
                                                   width: '100%',
                                                   maxWidth: '300px',
                                                   padding: '8px',
                                                   boxSizing: 'border-box'
                                                 }}
                                                placeholder="Filter the time cards using the driver"
                                                name="driver"
                                                value={filters.driver}
                                                onChange={handleTimeCardSearch}
                                            />
                                        </div>
                                    }
                                <div className="filter-item">
                                        <label className="text-light filter-dropdown-label" >
                                            Show only unverified time cards
                                        </label>
                                        <input
                                          type="checkbox"
                                          name="isTripCardVerified"
                                          checked={filters.isTripCardVerified}
                                          onChange={handleTimeCardSearch}
                                          style={{
                                            width: '30px',
                                            height: '30px',
                                            cursor: 'pointer',
                                          }}
                                        />
                                </div>
                            </div>
                            <div id="log-sheet-table">
                                <LogSheetTableView logSheets={filteredSheets} editURL={`/admin/update/driver/logsheet/`} isAggregated={true} isAdmin={true} isDriverNameRequired={true} isBillingFeatureRequired={true} setDeleteLogSheetId={setDeleteLogSheetId} handleShowDeleteMessage={handleShowDeleteMessage} isReimbursementRequired={isReimbursementRequired} flipSheetStatus={flipSheetStatus}/>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <PreparingDownload showModal={showLoadingModal} hideModal={hideLoadingModal}  isLoading={loadingModal} />
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={deleteThisLogSheet} id={deleteLogSheetId} message={deleteMessage} loadingModal={loadingModal} />
            {LoadingScreen(loading)}
            <ToastifyMessage />
        </Base>
    );
}