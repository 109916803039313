import React from 'react';
import Select from 'react-select';
import './FilterDropdown.css'; // Import the CSS file

const FilterDropdown = ({ label, options, onChange, value, placeholder=null }) => {

    // Custom styles for react-select
    const customStyles = {
      control: (provided) => ({
        ...provided,
        borderColor: '#ccc',
        backgroundColor: '#fff',
        color: '#333', // Text color inside the control
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: '#fff', // Background color of the dropdown menu
        zIndex: 1050,
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#333', // Text color for options
        backgroundColor: state.isSelected ? '#007bff' : '#fff', // Background color when selected
        ':hover': {
          backgroundColor: '#f1f1f1', // Hover effect
        },
      }),
      singleValue: (provided) => ({
        ...provided,
        color: '#333', // Text color when a value is selected
      }),
      placeholder: (provided) => ({
        ...provided,
        color: '#aaa', // Placeholder text color
      }),
      clearIndicator: (provided) => ({
        ...provided,
        color: '#aaa', // Clear indicator color (X)
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        color: '#aaa', // Dropdown arrow color
      }),
    };

  return (
    <div className="filter-dropdown">
      {label && <label className="filter-dropdown-label">{label}</label>}
      <Select
        options={options}
        value={value}
        onChange={onChange}
        isClearable
        isSearchable
        placeholder={placeholder || `Select ${label}`}
        styles={customStyles}
        className="filter-dropdown-select"
        classNamePrefix="react-select"
      />
    </div>
  );
};

export default FilterDropdown;
