const imageCache = new Map();

/**
 * Fetches an image from a URL, caches it, and returns its Base64 representation.
 * @param {string} url - The URL of the image.
 * @returns {Promise<string>} - The Base64 representation of the image.
 */
export const fetchAndCacheImage = async (url) => {
    if (imageCache.has(url)) {
        return imageCache.get(url);
    }

    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();

    return new Promise((resolve) => {
        reader.onloadend = () => {
            imageCache.set(url, reader.result);
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });
};

export const clearImageCache = () => {
    imageCache.clear();
};