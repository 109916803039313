import React, { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Typography,
} from "@mui/material";
import "./ImageUploaderWithCropper.css";
import ToastifyMessage from "../ToastifyMessage";
import {toastifyMessage} from '../Toast';

const ImageUploaderWithCropper = ({ fileInputRef, onSave, onRemove, photoFieldName, imageUploaded, setImageUploaded }) => {
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState(null);   
    const [loading, setLoading] = useState(false);
    const cropperRef = useRef(null);
    
    // Allowed image formats
    const validImageTypes = [
      "image/jpeg", "image/png", "image/gif", "image/webp", "image/tiff",
      "image/bmp", "image/svg+xml", "image/vnd.adobe.photoshop", "image/x-icon"
    ];

    // Handle file selection and validation
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check if the file type is valid
            if (!validImageTypes.includes(file.type)) {
                toastifyMessage("error", 'Invalid file type. Please upload a valid image');
                return;
            }
            // Check if the file size is below the 5MB limit
            if (file.size > 10 * 1024 * 1024) {
                toastifyMessage("error", 'File size exceeds 10MB. Please upload a smaller image');
                return;
            }
            
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
                setOpen(true);
            };
            reader.readAsDataURL(file);
        }
    };

    // Handle save button and convert cropped image to File
    const handleSave = () => {
        setLoading(true);
        const cropper = cropperRef.current.cropper;
        const croppedCanvas = cropper.getCroppedCanvas();

        croppedCanvas.toBlob(
            (blob) => {
                if (blob) {
                    // Create a file from the cropped canvas blob
                    const croppedFile = new File([blob], "cropped-image.jpg", { type: blob.type });
                    setLoading(false);
                    resetDialog();
                    setImageUploaded(true);

                    // Send the cropped image file to the parent component
                    onSave(photoFieldName, croppedFile);
                }
            },
            "image/jpeg"
        );
    };

    // Handle cancel scenario by resetting the dialog box
    const handleCancel  = () => {
        setOpen(false);
        setImage(null);
        onRemove(photoFieldName);
    };

    // Handle reset dialog box scenario by resetting the dialog box
    const resetDialog  = () => {
        setOpen(false);
        setImage(null);
        fileInputRef.current.value = null;
    };

    return (
        <div className="image-uploader-parent">
            <Button
                variant="contained"
                component="label"
                className={`upload-button ${imageUploaded ? 'bg-danger' : 'bg-success'}`}
            >
                {imageUploaded ? "Image Uploaded": "Upload Image"}
                <input ref={fileInputRef} type="file" hidden accept="image/*" onChange={handleFileChange} />
            </Button>

            <Dialog open={open} onClose={resetDialog} maxWidth="sm" fullWidth>
                <DialogTitle className="dialog-title">
                    <Typography variant="h5">Crop Image</Typography>
                </DialogTitle>
                <DialogContent className="custom-dialog-content">
                    {image && (
                        <Cropper
                            src={image}
                            className="cropper-container"
                            initialAspectRatio={NaN}
                            guides={true}
                            ref={cropperRef}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCancel}
                        variant="outlined"
                        className="cancel-button"
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        variant="contained"
                        className="save-button"
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : "Save"}
                    </Button>
                </DialogActions>
            </Dialog>
            <ToastifyMessage/> 
        </div>
    );
};

export default ImageUploaderWithCropper;