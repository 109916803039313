// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-dropdown {
    margin-bottom: 20px;
    width: 300px;
  }
  
  .filter-dropdown-label {
    font-weight: normal;
    margin-bottom: 5px;
    display: block;
    font-size: 16px;
  }

  .react-select__control {
    border-color: #ccc;
    transition: border-color 0.2s;
  }
  
  .react-select__control:hover {
    border-color: #888;
  }
  
  .react-select__menu {
    z-index: 9999;
  }  `, "",{"version":3,"sources":["webpack://./src/main/core/helper/FilterWithDropdown/FilterDropdown.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,6BAA6B;EAC/B;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,aAAa;EACf","sourcesContent":[".filter-dropdown {\n    margin-bottom: 20px;\n    width: 300px;\n  }\n  \n  .filter-dropdown-label {\n    font-weight: normal;\n    margin-bottom: 5px;\n    display: block;\n    font-size: 16px;\n  }\n\n  .react-select__control {\n    border-color: #ccc;\n    transition: border-color 0.2s;\n  }\n  \n  .react-select__control:hover {\n    border-color: #888;\n  }\n  \n  .react-select__menu {\n    z-index: 9999;\n  }  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
