import React, { useState, useEffect } from 'react';
import Base from "../../core/Base";
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { Link, Redirect } from "react-router-dom"
import { createtripsheet, getmerchants, gettrucks, gettrailers, getcommodities, getlocations, getdrivers } from '../helper/adminapicall';
import LoadingScreen from '../../core/helper/LoadingScreen';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import ImageUploaderWithCropper from '../../core/helper/ImageCropper/ImageUploaderWithCropper';
import { ENABLE_IMAGE_CROPPING } from '../../backend';

export default function AddTripSheetAdmin() {

    // Kill switch based implementation for cropper image
    const image_cropper_enabled_prop = (ENABLE_IMAGE_CROPPING).toString().toLowerCase();
    const isImageCropperEnabled = image_cropper_enabled_prop==="true" ? true : false;
    
    const { admin, token } = isAuthenticated();

    const [values, setValues] = useState({
        driverNo: "",
        truckNo: "",
        otherTruckNo: "",
        trailerNo: "",
        otherTrailerNo: "",
        pickUpLocation: "",
        otherPickUpLocation: "",
        pickUpCompany: "",
        otherPickUpCompany: "",
        pickUpCommodity: "",
        otherPickUpCommodity: "",
        pickUpTimeIn: "",
        pickUpTimeOut: "",
        inBoundWeight: "",
        ticketNumberAtShipping: "",
        date: moment().format('YYYY-MM-DD'),
        deliveryLocation: "",
        otherDeliveryLocation: "",
        deliveryTimeIn: "",
        deliveryTimeOut: "",
        outBoundWeight: "",
        ticketNumberAtReceiving: "",
        deductions: "",
        receivingDate: moment().format('YYYY-MM-DD'),
        isDeliveryIncomplete: false,
        fuelRate: "",
        waitRate: "",
        drivers: "",
        partners: "",
        trucks: "",
        trailers: "",
        commodities: "",
        locations: "",
        photo1: "",
        photo2: "",
        photo3: "",
        photo4: "",
        photo5: "",
        photo6: "",
        loading: false,
        isLoaded: "",
        error: "",
        CreatedPickTripSheet: "",
        formData: "",
        didRedirect: false
    });

    const ref1 = React.useRef();
    const ref2 = React.useRef();
    const ref3 = React.useRef();
    const ref4 = React.useRef();
    const ref5 = React.useRef();
    const ref6 = React.useRef();
    const [image1Uploaded, setImage1Uploaded] = useState(false);
    const [image2Uploaded, setImage2Uploaded] = useState(false);
    const [image3Uploaded, setImage3Uploaded] = useState(false);
    const [image4Uploaded, setImage4Uploaded] = useState(false);
    const [image5Uploaded, setImage5Uploaded] = useState(false);
    const [image6Uploaded, setImage6Uploaded] = useState(false);

    const { driverNo, truckNo, otherTruckNo, trailerNo, otherTrailerNo, pickUpLocation, otherPickUpLocation, pickUpCompany, otherPickUpCompany, pickUpCommodity, otherPickUpCommodity, date, receivingDate, isDeliveryIncomplete,
        pickUpTimeIn, pickUpTimeOut, inBoundWeight, ticketNumberAtShipping, deliveryLocation, otherDeliveryLocation, deliveryTimeIn, deliveryTimeOut, outBoundWeight, ticketNumberAtReceiving, fuelRate, waitRate, deductions, drivers, partners, commodities, trucks,
        trailers, locations, CreatedPickTripSheet, photo1, photo2, photo3, photo4, photo5, photo6, loading, error, isLoaded, formData, didRedirect } = values;

        const preload = () => {
            setValues({ ...values, loading: true, isLoaded: ""})
            getmerchants(admin._id, token).then(data1 => {
                if (data1.error) {
                    setValues({ ...values, error: data1.error,  loading: "", isLoaded: ""});
                } else {
                    gettrucks(admin._id, token).then(data2 => {
                        if (data2.error) {
                            setValues({ ...values, error: data2.error, loading: "", isLoaded: "" });
                        } else {
                            gettrailers(admin._id, token).then(data3 => {
                                if (data3.error) {
                                    setValues({ ...values, error: data3.error, loading: "", isLoaded: "" });
                                } else {
                                    getcommodities(admin._id, token).then(data4 => {
                                        if (data4.error) {
                                            setValues({ ...values, error: data4.error });
                                        } else {
                                            getlocations(admin._id, token).then(data5 => {
                                                if (data5.error) {
                                                    setValues({ ...values, error: data5.error });
                                                } else {
                                                    getdrivers(admin._id, token).then(data6 => {
                                                        if (data6.error) {
                                                            setValues({ ...values, error: data6.error });
                                                        } else {
                                                            setValues({ 
                                                                ...values, 
                                                                partners: data1, 
                                                                trucks: data2, 
                                                                trailers: data3, 
                                                                commodities: data4,
                                                                locations: data5,
                                                                drivers: data6,
                                                                loading: "", 
                                                                isLoaded: true, 
                                                                formData: new FormData() 
                                                            });
                                                        }
                                                    })
                                                    .catch(err => {
                                                        console.log('Error loading the Trip sheet form:', err);
                                                        setValues({ ...values, error: "Error loading the Trip sheet form", loading: "" });
                                                        toastifyMessage("error", 'Error loading the Trip sheet form');
                                                    });
                                                }     
                                        })
                                        .catch(err => {
                                            console.log('Error loading the Trip sheet form:', err);
                                            setValues({ ...values, error: "Error loading the Trip sheet form", loading: "" });
                                            toastifyMessage("error", 'Error loading the Trip sheet form');
                                        });
                                    }
                                })
                                .catch(err => {
                                    console.log('Error loading the Trip sheet form:', err);
                                    setValues({ ...values, error: "Error loading the Trip sheet form", loading: "" });
                                    toastifyMessage("error", 'Error loading the Trip sheet form');
                                });
                            }
                        })
                        .catch(err => {
                            console.log('Error loading the Trip sheet form:', err);
                            setValues({ ...values, error: "Error loading the Trip sheet form", loading: "" });
                            toastifyMessage("error", 'Error loading the Trip sheet form');
                        });
                    }
                    })
                    .catch(err => {
                        console.log('Error loading the Trip sheet form:', err);
                        setValues({ ...values, error: "Error loading the Trip sheet form", loading: "" });
                        toastifyMessage("error", 'Error loading the Trip sheet form');
                    });
                };
            })
            .catch(err => {
                console.log('Error loading the Trip sheet form:', err);
                setValues({ ...values, error: "Error loading the Trip sheet form", loading: "" });
                toastifyMessage("error", 'Error loading the Trip sheet form');
            });
        }
        
    useEffect(() => {
        preload();
    }, []);

    const successMessage = () => (
        <div className="alert alert-success mt-3"
            style={{ display: CreatedPickTripSheet ? "" : "none" }}
        >
            <h4>{CreatedPickTripSheet}</h4>
        </div>
    );

    const warningMessage = () => (
        <div className="alert alert-danger mt-3"
            style={{ display: error ? "" : "none" }}
        >
            <h4>{error}</h4>
        </div>
    );
   
    const onSubmit = (event) => {
        event.preventDefault();
        setValues({ ...values, error: "", loading: true, CreatedPickTripSheet: "" });

        // Field validations
        const requiredFields = [truckNo, trailerNo, pickUpLocation, deliveryLocation, pickUpCompany, pickUpCommodity, pickUpTimeIn, pickUpTimeOut, deliveryTimeIn, deliveryTimeOut, inBoundWeight, outBoundWeight, ticketNumberAtShipping, ticketNumberAtReceiving, date, receivingDate];
        if (requiredFields.some(field => !field)) {
            if(!pickUpLocation){
                setValues({ ...values, error: "Enter a valid location", loading: "", CreatedPickTripSheet: '' });
                toastifyMessage("warning", "Enter a valid location");
                return;
            }
            if(!deliveryLocation){
                setValues({ ...values, error: "Enter a valid location", loading: "", CreatedDropTripSheet: '' });
                toastifyMessage("warning", "Enter a valid location");
                return;
            }
            setValues({ ...values, error: 'All the fields marked with * are mandatory', loading: "", CreatedPickTripSheet: '' });
            toastifyMessage("warning", 'All the fields marked with * are mandatory');
            return;
        }

        if(truckNo === "other" && (otherTruckNo === "")){ 
            setValues({ ...values, error: 'Enter valid truck number', loading: "", CreatedPickTripSheet: '' });
            toastifyMessage("warning", 'Enter valid truck number');
            return;
        }

        if(trailerNo === "other" && (otherTrailerNo === "")){ 
            setValues({ ...values, error: 'Enter valid trailer number', loading: "", CreatedPickTripSheet: '' });
            toastifyMessage("warning", 'Enter valid trailer number');
            return;
        }

        if(pickUpCompany === "other" && (otherPickUpCompany === "")){ 
            setValues({ ...values, error: 'Enter valid customer', loading: "", CreatedPickTripSheet: '' });
            toastifyMessage("warning", 'Enter valid customer');
            return;
        }

        if(pickUpCommodity === "other" && (otherPickUpCommodity === "")){ 
            setValues({ ...values, error: 'Enter valid commodity name', loading: "", CreatedPickTripSheet: '' });
            toastifyMessage("warning", 'Enter valid commodity name');
            return;
        }

        if(pickUpLocation === "other" && (otherPickUpLocation === "")){ 
            setValues({ ...values, error: 'Enter valid pickup location', loading: "", CreatedPickTripSheet: '' });
            toastifyMessage("warning", 'Enter valid pickup location');
            return;
        }

        if(deliveryLocation === "other" && (otherDeliveryLocation === "")){ 
            setValues({ ...values, error: 'Enter valid delivery location', loading: "", CreatedDropTripSheet: '' });
            toastifyMessage("warning", 'Enter valid delivery location');
            return;
        }

        // Set the date field in the form in case the date is set to default
        if (!formData.get('date')) {
            formData.set('date', date)
        }

         // Set the receiving date field in the form in case the date is set to default
         if (!formData.get('receivingDate')) {
            formData.set('receivingDate', receivingDate)
        }

        // Set Fuel Rate for the sheet
        if(fuelRate){
            formData.set('fuelRate', fuelRate);
        }

        // Set Wait Rate for the sheet
        if(waitRate){
            formData.set('waitRate', waitRate);
        }

        if (!formData.get('isIncompleteFlag')) {
            formData.set('isIncompleteFlag', false)
          }

        createtripsheet(admin._id, formData, token)
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error, loading: "", CreatedPickTripSheet: "" })
                    toastifyMessage("error", data.error);
                } else {
                    // Redirect to Driver Dashboard
                    setValues({
                        ...values,
                        driverNo: "",
                        truckNo: "",
                        otherTruckNo: "",
                        trailerNo: "",
                        otherTrailerNo: "",
                        date: "",
                        pickUpLocation: "",
                        otherPickUpLocation: "",
                        deliveryLocation: "",
                        otherDeliveryLocation: "",
                        pickUpCompany: "",
                        otherPickUpCompany: "",
                        pickUpCommodity: "",
                        otherPickUpCommodity: "",
                        pickUpTimeIn: "",
                        pickUpTimeOut: "",
                        inBoundWeight: "",
                        ticketNumberAtShipping: "",
                        deliveryTimeIn: "",
                        deliveryTimeOut: "",
                        outBoundWeight: "",
                        ticketNumberAtReceiving: "",
                        deductions: "",
                        receivingDate: "",
                        isDeliveryIncomplete: false,
                        fuelRate: "",
                        waitRate: "",
                        pickupLocationSearch: "",
                        deliveryLocationSearch: "",
                        photo1: "",
                        photo2: "",
                        photo3: "",
                        photo4: "",
                        photo5: "",
                        photo6: "",
                        loading: "",
                        error: "",
                        CreatedPickTripSheet: "Trip Sheet created succesfully",
                        formData: new FormData(),
                        didRedirect: true
                    });
                    setPickupLocationSearch("");
                    setDeliveryLocationSearch("");

                    ref1.current.value = "";
                    ref2.current.value = "";
                    ref3.current.value = "";
                    ref4.current.value = "";
                    ref5.current.value = "";
                    ref6.current.value = "";
                    // Clear the cropper state
                    resetCropper();

                    setTimeout(() => {
                        toastifyMessage("success", 'Trip Sheet created successfully');
                    }, 200);
                }
            })
            .catch(err => {
                console.log('Error creating the Trip sheet form:', err);
                setValues({ ...values, error: "Error creating the Trip sheet form", loading: "", CreatedPickTripSheet: "" });
                toastifyMessage("error", 'Error creating the Trip sheet form');
              });
    };

    const resetCropper = () => {
        if(isImageCropperEnabled){
            setImage1Uploaded(false);
            setImage2Uploaded(false);
            setImage3Uploaded(false);
            setImage4Uploaded(false);
            setImage5Uploaded(false);
            setImage6Uploaded(false);
        }
    };

    const handleSaveImage = (name, file) => {
        const value = ((name === "photo1") || (name === "photo2") || (name === "photo3") || (name === "photo4") || (name === "photo5") || (name === "photo6")) ? file : "";
        setValues({ ...values, [name]: value })
        formData.set(name, value) 
    };

    const handleRemoveImage = (name) => {
        setValues({ ...values, [name]: "" })
        if(formData.get(name)){
            formData.delete(name);
        }
        if(name=="photo1"){
            ref1.current.value = "";
            setImage1Uploaded(false);
        }
        else if(name=="photo2"){
            ref2.current.value = "";
            setImage2Uploaded(false);
        }
        else if(name=="photo3"){
            ref3.current.value = "";
            setImage3Uploaded(false);
        }
        else if(name=="photo4"){
            ref4.current.value = "";
            setImage4Uploaded(false);
        }
        else if(name=="photo5"){
            ref5.current.value = "";
            setImage5Uploaded(false);
        }
        else if(name=="photo6"){
            ref6.current.value = "";
            setImage6Uploaded(false);
        }
    };

    const handleChange = name => event => {
        const value = ((name === "photo1") || (name === "photo2") || (name === "photo3") || (name === "photo4") || (name === "photo5") || (name === "photo6")) ? event.target.files[0] : event.target.value;
 
        if(((name === "trailerNo") || (name === "truckNo") || (name === "pickUpCompany") || (name === "pickUpCommodity") || (name === "pickUpLocation") || (name === "deliveryLocation")) && ((value !== "other"))){
            if(name==="trailerNo"){
                setValues({ ...values, [name]: value, otherTrailerNo: "" })
                formData.set(name, value)
                if(formData.get("otherTrailerNo") || formData.get("otherTrailerNo")==="" ){
                    formData.delete("otherTrailerNo")
                }
            }

            if(name==="truckNo"){
                setValues({ ...values, [name]: value, otherTruckNo: "" })
                formData.set(name, value)
                if(formData.get("otherTruckNo") || formData.get("otherTruckNo")===""){
                    formData.delete("otherTruckNo")
                }
            }

            if(name==="pickUpCompany"){
                let p = partners.find(partner => partner._id === value);
                let fr = "";
                if(p && p.fuelRate){
                    fr = p.fuelRate;
                }

                let wr = "";
                if(p && p.waitRate){
                    wr = p.waitRate;
                }
                setValues({ ...values, [name]: value, otherPickUpCompany:"", fuelRate: fr, waitRate: wr});
                formData.set(name, value)
                if(formData.get("otherPickUpCompany") || formData.get("otherPickUpCompany")===""){
                    formData.delete("otherPickUpCompany")
                }
            }

            if(name==="pickUpCommodity"){
                setValues({ ...values, [name]: value, otherPickUpCommodity: "" })
                formData.set(name, value)
                if(formData.get("otherPickUpCommodity") || formData.get("otherPickUpCommodity")===""){
                    formData.delete("otherPickUpCommodity")
                }
            }

            if(name==="pickUpLocation"){
                setValues({ ...values, [name]: value, otherPickUpLocation: "" })
                formData.set(name, value)
                if(formData.get("otherPickUpLocation") || formData.get("otherPickUpLocation")===""){
                    formData.delete("otherPickUpLocation")
                }
            }

            if(name==="deliveryLocation"){
                setValues({ ...values, [name]: value, otherDeliveryLocation: "" })
                formData.set(name, value)
                if(formData.get("otherDeliveryLocation") || formData.get("otherDeliveryLocation")==="" ){
                    formData.delete("otherDeliveryLocation")
                }
            }
        }
        else{
            if(name === 'pickUpCompany' && value === "other"){
                setValues({ ...values, [name]: value });
                formData.set(name, value);
            }
            else{
                setValues({ ...values, [name]: value })
                formData.set(name, value)
            }
        }
    };

    // Pickup location search
    const [pickupLocationSearch, setPickupLocationSearch] = useState("");
    const [showOtherPickupLocation, setShowOtherPickupLocation] = useState(false);
    const [filteredPickupLocations, setFilteredPickupLocations] = useState([]);
    
    const handlePickupLocationSearch = (event) => {
        const searchTerm = event.target.value;
        setPickupLocationSearch(searchTerm);

        const filtered = locations.filter(location =>
            location.location.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredPickupLocations(filtered);

        const exactMatch = filtered.find(loc => loc.location.toLowerCase() === searchTerm.toLowerCase());
        if (exactMatch) {
            selectPickupLocation(exactMatch);
        } else {
            setValues(prevValues => ({ ...prevValues, pickUpLocation: "" }));
            formData.delete("pickUpLocation");
        }
    };

        // if (searchTerm.toLowerCase() === "other") {
        //     setShowOtherPickupLocation(true);
        //     setValues(prevValues => ({ ...prevValues, pickUpLocation: "other" }));
        //     formData.set("pickUpLocation", "other");
        // } else {
        //     setShowOtherPickupLocation(false);
        //     const filtered = locations.filter(location =>
        //         location.location.toLowerCase().includes(searchTerm.toLowerCase())
        //     );
        //     setFilteredPickupLocations(filtered);

        //     const exactMatch = filtered.find(loc => loc.location.toLowerCase() === searchTerm.toLowerCase());
        //     if (exactMatch) {
        //         selectPickupLocation(exactMatch);
        //     } else {
        //         setValues(prevValues => ({ ...prevValues, pickUpLocation: "" }));
        //         formData.delete("pickUpLocation");
        //     }
        // }

    const selectPickupLocation = (location) => {
        setValues(prevValues => ({ ...prevValues, pickUpLocation: location.location }));
        setPickupLocationSearch(location.location);
        formData.set("pickUpLocation", location.location);
        setFilteredPickupLocations([]);
    };

    // const handleOtherPickupLocationChange = (event) => {
    //     const value = event.target.value;
    //     setValues(prevValues => ({ ...prevValues, otherPickUpLocation: value }));
    //     formData.set("otherPickUpLocation", value);
    // };

    // Delivery location search
    const [deliveryLocationSearch, setDeliveryLocationSearch] = useState("");
    const [showOtherDeliveryLocation, setShowOtherDeliveryLocation] = useState(false);
    const [filteredDeliveryLocations, setFilteredDeliveryLocations] = useState([]);

    const handleDeliveryLocationSearch = (event) => {
        const searchTerm = event.target.value;
        setDeliveryLocationSearch(searchTerm);

        const filtered = locations.filter(location =>
            location.location.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDeliveryLocations(filtered);

        const exactMatch = filtered.find(loc => loc.location.toLowerCase() === searchTerm.toLowerCase());
        if (exactMatch) {
            selectDeliveryLocation(exactMatch);
        } else {
            setValues(prevValues => ({ ...prevValues, deliveryLocation: "" }));
            formData.delete("deliveryLocation");
        }
    };

        // if (searchTerm.toLowerCase() === "other") {
        //     setShowOtherDeliveryLocation(true);
        //     setValues(prevValues => ({ ...prevValues, deliveryLocation: "other" }));
        //     formData.set("deliveryLocation", "other");
        // } else {
        //     setShowOtherDeliveryLocation(false);
        //     const filtered = locations.filter(location =>
        //         location.location.toLowerCase().includes(searchTerm.toLowerCase())
        //     );
        //     setFilteredDeliveryLocations(filtered);

        //     const exactMatch = filtered.find(loc => loc.location.toLowerCase() === searchTerm.toLowerCase());
        //     if (exactMatch) {
        //         selectDeliveryLocation(exactMatch);
        //     } else {
        //         setValues(prevValues => ({ ...prevValues, deliveryLocation: "" }));
        //         formData.delete("deliveryLocation");
        //     }
        // }

    const selectDeliveryLocation = (location) => {
        setValues(prevValues => ({ ...prevValues, deliveryLocation: location.location }));
        setDeliveryLocationSearch(location.location);
        formData.set("deliveryLocation", location.location);
        setFilteredDeliveryLocations([]);
    };

    // const handleOtherDeliveryLocationChange = (event) => {
    //     const value = event.target.value;
    //     setValues(prevValues => ({ ...prevValues, otherDeliveryLocation: value }));
    //     formData.set("otherDeliveryLocation", value);
    // };

    const TripSheetForm = () => (
        <form className="mt-4" style={{ display: (trucks !== "" || trailers !== "" || partners !== "" || commodities !== ""  || locations !== "" || drivers !== "") ? "" : "none" }}>

            <div className="form-group">
                <label className="text-light"> Driver <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("driverNo")}
                    className="form-control"
                    placeholder="Driver"
                >
                    <option value="" selected={driverNo===""}>Select the Driver</option>
                    {drivers && drivers.map((driver, index) => {
                        return (
                            <option key={index} value={driver._id}>
                                {driver.firstName + ' ' + driver.lastName }
                            </option>
                        )
                    })}
                </select>
            </div>

            <div className="form-group">
                <label className="text-light"> Truck <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("truckNo")}
                    className="form-control"
                    placeholder="Truck"
                >
                    <option value="" selected={truckNo===""}>Select the Truck</option>
                    {trucks && trucks.map((truck, index) => {
                        return (
                            <option key={index} value={truck._id}>
                                {truck.truckNumber}
                            </option>
                        )
                    })}
                    <option value="other" selected={truckNo==="other"}>Other</option>
                </select>
            </div>

            <div className="form-group" style={{ display: ( truckNo && (truckNo === "other")) ? "" : "none" }}>
                <label className="text-light">Other Truck <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherTruckNo')}
                    className="form-control"
                    placeholder="Truck Number"
                    value={otherTruckNo} />
            </div>

            <div className="form-group">
                <label className="text-light"> Trailer <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("trailerNo")}
                    className="form-control"
                    placeholder="Trailer"
                >
                    <option value="" selected={trailerNo===""}>Select the Trailer</option>
                    {trailers && trailers.map((trailer, index) => {
                        return (
                            <option key={index} value={trailer._id}>
                                {trailer.trailerNumber}
                            </option>
                        )
                    })}
                    <option value="other" selected={trailerNo==="other"}>Other</option>
                </select>
            </div>

            <div className="form-group" style={{ display: ( trailerNo && (trailerNo === "other")) ? "" : "none" }}>
                <label className="text-light">Other Trailer <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherTrailerNo')}
                    className="form-control"
                    placeholder="Trailer Number"
                    value={otherTrailerNo} />
            </div>

            <div className="form-group">
                <label className="text-light"> Customer <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("pickUpCompany")}
                    className="form-control"
                    placeholder="Company"
                >
                    <option value="" selected={pickUpCompany===""}>Select the Customer</option>
                    {partners && partners.map((partner, index) => {
                        return (
                            <option key={index} value={partner._id}>
                                {partner.partnerName}
                            </option>
                        )
                    })}
                </select>
            </div>

            <div className="form-group">
                <label className="text-light">Pickup Location <span className="text-warning">*</span></label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search for a location"
                    value={pickupLocationSearch}
                    onChange={handlePickupLocationSearch}
                />
                {pickupLocationSearch && (
                    <ul className="list-group mt-2">
                        {filteredPickupLocations.map((location, index) => (
                            <li
                                key={index}
                                className="list-group-item list-group-item-action"
                                onClick={() => selectPickupLocation(location)}
                            >
                                {location.location}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            {/* {showOtherPickupLocation && (
                <div className="form-group">
                    <label className="text-light">Other Pickup Location <span className="text-warning">*</span></label>
                    <input
                        onChange={handleOtherPickupLocationChange}
                        className="form-control"
                        placeholder="Enter custom location"
                        value={values.otherPickUpLocation || ''}
                    />
                </div>
            )} */}

            <div className="form-group">
                <label className="text-light">End Location <span className="text-warning">*</span></label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search for a location"
                    value={deliveryLocationSearch}
                    onChange={handleDeliveryLocationSearch}
                />
                {deliveryLocationSearch && (
                    <ul className="list-group mt-2">
                        {filteredDeliveryLocations.map((location, index) => (
                            <li
                                key={index}
                                className="list-group-item list-group-item-action"
                                onClick={() => selectDeliveryLocation(location)}
                            >
                                {location.location}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            {/* {showOtherDeliveryLocation && (
                <div className="form-group">
                    <label className="text-light">Other End Location <span className="text-warning">*</span></label>
                    <input
                        onChange={handleOtherDeliveryLocationChange}
                        className="form-control"
                        placeholder="Enter custom location"
                        value={values.otherDeliveryLocation || ''}
                    />
                </div>
            )} */}

            <div className="form-group">
                <label className="text-light"> Commodity <span className="text-warning">*</span></label>
                <select
                    onChange={handleChange("pickUpCommodity")}
                    className="form-control"
                    placeholder="Commodity"
                >
                    <option value="" selected={pickUpCommodity===""}>Select the Commodity</option>
                    {commodities && commodities.map((commodity, index) => {
                        return (
                            <option key={index} value={commodity._id}>
                                {commodity.commodityName}
                            </option>
                        )
                    })}
                    {/* <option value="other" selected={pickUpCommodity==="other"}>Other</option> */}
                </select>
            </div>

            {/* <div className="form-group" style={{ display: ( pickUpCommodity && (pickUpCommodity === "other")) ? "" : "none" }}>
                <label className="text-light">Other Commodity <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange('otherPickUpCommodity')}
                    className="form-control"
                    placeholder="Commodity"
                    value={otherPickUpCommodity} />
            </div> */}

            <div className="form-group">
                <label className="text-light"> Shipping Date <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("date")}
                    type="date"
                    name="date"
                    className="form-control"
                    value={date}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Pickup Scale In <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("pickUpTimeIn")}
                    type="time"
                    className="form-control"
                    name="pickUpTimeIn"
                    value={pickUpTimeIn}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Pickup Scale Out <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("pickUpTimeOut")}
                    type="time"
                    className="form-control"
                    value={pickUpTimeOut}
                    name='pickUpTimeOut'
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Receiving Date <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("receivingDate")}
                    type="date"
                    name="receivingDate"
                    className="form-control"
                    value={receivingDate}
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Delivery Scale In <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("deliveryTimeIn")}
                    type="time"
                    className="form-control"
                    name="deliveryTimeIn"
                    value={deliveryTimeIn}
                    required
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Delivery Scale Out <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("deliveryTimeOut")}
                    type="time"
                    className="form-control"
                    value={deliveryTimeOut}
                    name='deliveryTimeOut'
                    required
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Net Inbound Weight (in lbs) <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("inBoundWeight")}
                    type="number"
                    className="form-control"
                    value={inBoundWeight}
                    name='inBoundWeight'
                     placeholder="Weight (in lbs)"
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Net Outbound Weigh (in lbs) <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("outBoundWeight")}
                    type="number"
                    className="form-control"
                    value={outBoundWeight}
                    name='outBoundWeight'
                    placeholder="Weight (in lbs)"
                    required
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Ticket Number At Shipping <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("ticketNumberAtShipping")}
                    type="text"
                    className="form-control"
                    value={ticketNumberAtShipping}
                    name='ticketNumberAtShipping'
                    placeholder="Ticket Number"
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Ticket Number At Delivery <span className="text-warning">*</span></label>
                <input
                    onChange={handleChange("ticketNumberAtReceiving")}
                    type="text"
                    className="form-control"
                    value={ticketNumberAtReceiving}
                    name='ticketNumberAtReceiving'
                    placeholder="Ticket Number"
                    required
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Deductions </label>
                <input
                    onChange={handleChange("deductions")}
                    type="text"
                    className="form-control"
                    value={deductions}
                    name='deductions'
                    placeholder="Deductions"
                    required
                />
            </div>

            <div className="form-group">
                <label className="text-light"> Load Rejected <span className="text-warning">*</span></label>
                <select
                  onChange={handleChange("isDeliveryIncomplete")}
                  className="form-control"
                >
                  <option value={false} selected={isDeliveryIncomplete===false}>No</option>
                  <option value={true}>Yes</option>
                </select>
            </div>

            <span className="text-white"> Trip Photos </span>

            {isImageCropperEnabled ? 
                    <>
                        <ImageUploaderWithCropper fileInputRef={ref1} onSave={handleSaveImage} onRemove={handleRemoveImage} photoFieldName="photo1" imageUploaded={image1Uploaded} setImageUploaded={setImage1Uploaded} />
                        <ImageUploaderWithCropper fileInputRef={ref2} onSave={handleSaveImage} onRemove={handleRemoveImage} photoFieldName="photo2" imageUploaded={image2Uploaded} setImageUploaded={setImage2Uploaded}/>
                        <ImageUploaderWithCropper fileInputRef={ref3} onSave={handleSaveImage} onRemove={handleRemoveImage} photoFieldName="photo3" imageUploaded={image3Uploaded} setImageUploaded={setImage3Uploaded}/>
                        <ImageUploaderWithCropper fileInputRef={ref4} onSave={handleSaveImage} onRemove={handleRemoveImage} photoFieldName="photo4" imageUploaded={image4Uploaded} setImageUploaded={setImage4Uploaded} />
                        <ImageUploaderWithCropper fileInputRef={ref5} onSave={handleSaveImage} onRemove={handleRemoveImage} photoFieldName="photo5" imageUploaded={image5Uploaded} setImageUploaded={setImage5Uploaded}/>
                        <ImageUploaderWithCropper fileInputRef={ref6} onSave={handleSaveImage} onRemove={handleRemoveImage} photoFieldName="photo6" imageUploaded={image6Uploaded} setImageUploaded={setImage6Uploaded}/>
                    </>
                 :
                    <>
                        <div className="form-group">
                          <label className="btn btn-block btn-success rounded">
                            <input
                              onChange={handleChange("photo1")}
                              type="file"
                              name="photo1"
                              accept="image/*"
                              placeholder="Choose an image for the trip sheet"
                              ref={ref1}
                            />
                          </label>
                        </div>

                        <div className="form-group">
                          <label className="btn btn-block btn-success rounded">
                            <input
                              onChange={handleChange("photo2")}
                              type="file"
                              name="photo2"
                              accept="image/*"
                              placeholder="Choose an image for the trip sheet"
                              ref={ref2}
                            />
                          </label>
                        </div>

                        <div className="form-group">
                          <label className="btn btn-block btn-success rounded">
                            <input
                              onChange={handleChange("photo3")}
                              type="file"
                              name="photo3"
                              accept="image/*"
                              placeholder="Choose an image for the trip sheet"
                              ref={ref3}
                            />
                          </label>
                        </div>

                        <div className="form-group">
                          <label className="btn btn-block btn-success rounded">
                            <input
                              onChange={handleChange("photo4")}
                              type="file"
                              name="photo4"
                              accept="image/*"
                              placeholder="Choose an image for the trip sheet"
                              ref={ref4}
                            />
                          </label>
                        </div>

                        <div className="form-group">
                          <label className="btn btn-block btn-success rounded">
                            <input
                              onChange={handleChange("photo5")}
                              type="file"
                              name="photo5"
                              accept="image/*"
                              placeholder="Choose an image for the trip sheet"
                              ref={ref5}
                            />
                          </label>
                        </div>

                        <div className="form-group">
                          <label className="btn btn-block btn-success rounded">
                            <input
                              onChange={handleChange("photo6")}
                              type="file"
                              name="photo6"
                              accept="image/*"
                              placeholder="Choose an image for the trip sheet"
                              ref={ref6}
                            />
                          </label>
                        </div>
                    </>
            }

            <button type="submit" onClick={onSubmit} className="btn btn-outline-success mb-4 mt-3">
                Submit Load
            </button>

        </form>
    );

    return (
        <Base
            title="Trip Sheet Dashboard"
            description="Add trip sheet"
            className="container bg-success p-4"
        >
            <Link to="/driver/dashboard" className="btn btn-md btn-dark mb-3">
                Driver Home
            </Link>

            <div className="row bg-dark text-white rounded center">
                <div className="col-md-8 offset-md-1 col-10 offset-1 ">
                    {isLoaded && TripSheetForm()}
                    {successMessage()}
                    {warningMessage()}
                    {LoadingScreen(loading)}
                    <ToastifyMessage/> 
                </div>
            </div>
        </Base>
    );
}