import React, { useState, useEffect } from 'react';
import Base from '../../core/Base';
import { Link, useParams, useLocation } from 'react-router-dom';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { getalltripsheets, getdrivers, getcommodities, getlocations, getmerchants, deletetripsheet, fetchImageFromS3 } from '../helper/adminapicall';
import LoadingScreen from '../../core/helper/LoadingScreen';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import PreparingDownload from '../../core/helper/PreparingDownload';
import LoadsTableView from '../../core/helper/TableViews/LoadsTableView';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import '../AdminSideSheet.css';
import { 
    getTruckNo_2, getTrailerNo_2, getDriverName, getPickupCompany_2, getDeliveryLocation, getPickupLocation, getPickupCommodity,
    getWaitingPeriod, getTotalWaitingPeriod, getBillableWaitingPeriod, getWaitRate, getBillableWaitCharge, getNetUnit, getRatePerUnit, getLoadSubTotal, getFuelAmount
} from '../../core/helper/helperFunctions';
import Logo from "../../images/ttms_logo_2.jpg";
import { S3_INTEGRATION_ENABLED, ENABLE_MONGO_READ_IMAGE, ENABLE_TEXTDROPDOWN_FILTER } from "../../backend";
import { fetchAndCacheImage, clearImageCache } from '../../core/helper/Utils/DownloadUtils';
import FilterDropdown from '../../core/helper/FilterWithDropdown/FilterDropdown';

export default function ViewLoads() {

    const { admin, token } = isAuthenticated();
    const location = useLocation();

    // Kill switch based implementation for S3
    const s3_integration_enabled_prop = (S3_INTEGRATION_ENABLED).toString().toLowerCase();
    const isS3IntegrationEnabled = s3_integration_enabled_prop==="true" ? true : false;

    // Kill switch to disable images reads from mongo database
    const enable_mongo_read_image_prop = (ENABLE_MONGO_READ_IMAGE).toString().toLowerCase();
    const isMongoDBImageReadEnabled = enable_mongo_read_image_prop==="true" ? true : false;

    // Kill switch based implementation for text based dropdown filters
    const enable_text_dropdown_filter_prop = (ENABLE_TEXTDROPDOWN_FILTER).toString().toLowerCase();
    const isTextDropdownFilterEnabled = enable_text_dropdown_filter_prop==="true" ? true : false;

    // For date filters: end date is the current system date
    const [values, setValues] = useState({
        startDate: moment().subtract(5, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loading: "",
        loadingModal: "",
        error: "",
        isLoaded: "",
        tripSheets: []        
    });

    // Filter state
    const [filters, setFilters] = useState({
      location: '',
      ticketNumber: '',
      customer: '',
      driver: '',
      commodity: '',
      isLoadVerified: '',
    });
    const [filteredLoads, setFilteredLoads] = useState([]);

    const { startDate, endDate, loading, loadingModal, error, isLoaded, tripSheets } = values;

    const preload = () => {
        setValues({ ...values, error: "", loading: true, isLoaded: "" })
        getalltripsheets(admin._id, {startDate, endDate}, token).then(data1 => {
            if(data1.error) {
                setValues({ ...values, error: data1, loading: "", isLoaded: ""})
                toastifyMessage("error", "Error fetching the loads");
            }else{
                setValues({ 
                    ...values, 
                    tripSheets:data1,
                    error: "", 
                    loading: "", 
                    isLoaded: true});

                if(data1.length===0){
                    toastifyMessage("warning", "No data found");
                }
            }
        })
        .catch(err => {
            console.log('Error loading the loads:', err);
            setValues({ ...values, error: "Error loading the loads", loading: "" });
            toastifyMessage("error", 'Error loading the loads');
        });
    }

    const reloadInvoice = () => {
        setValues({ ...values, error: "", loading: true, isLoaded: "" })
        getalltripsheets(admin._id, {startDate, endDate}, token).then(data1 => {
            if(data1.error) {
                setValues({ ...values, error: data1, loading: "", isLoaded: ""})
                toastifyMessage("error", "Error fetching the loads");
            }else{
                setValues({ 
                    ...values, 
                    tripSheets:data1,
                    error: "", 
                    loading: "", 
                    isLoaded: true});
                
                if(data1.length===0){
                    toastifyMessage("warning", "No data found");
                }
            }
        })
        .catch(err => {
            console.log('Error loading the loads:', err);
            setValues({ ...values, error: "Error loading the loads", loading: "" });
            toastifyMessage("error", 'Error loading the loads');
        });
    }

    const updateInvoice = (id, data1, effectiveDate) => {
        // Update the tripSheets array in the state
        setValues(prevValues => ({
            ...prevValues,
            tripSheets: prevValues.tripSheets.map(sheet => {
                const shouldUpdate = 
                    (sheet._id === id) ||
                    (sheet.pickUpLocation === data1.pickUpLocation &&
                     sheet.deliveryLocation === data1.deliveryLocation &&
                     sheet.pickUpCompany[0]._id === data1.pickUpCompany._id &&
                     moment(sheet.date).isSameOrAfter(moment(effectiveDate))
                    );
    
                return shouldUpdate ? {
                    ...sheet,
                    ratePerUnit: data1.ratePerUnit || data1.ratePerUnit === 0 ? data1.ratePerUnit : sheet.ratePerUnit,
                    loadSubTotal: data1.loadSubTotal || data1.loadSubTotal === 0 ? data1.loadSubTotal : sheet.loadSubTotal,
                    ifFlatRate: (data1.ifFlatRate?true:false)
                } : sheet;
            })
        }));
    }

    const flipLoadVerificationSheetStatus = (id, status) => {
         // Update the tripSheets array in the state
         setValues(prevValues => ({
            ...prevValues,
            tripSheets: prevValues.tripSheets.map(
                sheet => sheet._id === id ? { ...sheet, isLoadVerified: status } : sheet
            )
        }));
    }

    const flipWaitingWaiverSheetStatus = (id, status) => {
        // Update the tripSheets array in the state
        setValues(prevValues => ({
           ...prevValues,
           tripSheets: prevValues.tripSheets.map(
               sheet => sheet._id === id ? { ...sheet, isWaitingTimeWaivedOff: status } : sheet
           )
       }));
   }

    // Preload the Loads
    useEffect(() => {
        preload();
    }, []);

    // Updates the filtered list whenever the tripsheets are updated
    useEffect(() => {
        const filtered = tripSheets.filter((sheet) => {
            return (
              (!filters.location || ((sheet.pickUpLocation?sheet.pickUpLocation:"").toLowerCase().includes(filters.location.toLowerCase()) || ((sheet.deliveryLocation?sheet.deliveryLocation:"").toLowerCase().includes(filters.location.toLowerCase())))) &&
              (!filters.ticketNumber || ((sheet.ticketNumberAtShipping?sheet.ticketNumberAtShipping:"").toLowerCase().includes(filters.ticketNumber.toLowerCase()) || ((sheet.ticketNumberAtReceiving?sheet.ticketNumberAtReceiving:"").toLowerCase().includes(filters.ticketNumber.toLowerCase())))) &&
              (!filters.customer || ((sheet.pickUpCompany && sheet.pickUpCompany[0].partnerName).toLowerCase().includes(filters.customer.toLowerCase()))) &&
              (!filters.driver || ((sheet.driverNo && (sheet.driverNo[0].firstName + ' ' + sheet.driverNo[0].lastName)).toLowerCase().includes(filters.driver.toLowerCase()))) &&
              (!filters.commodity || (((sheet.pickUpCommodity && sheet.pickUpCommodity[0])?sheet.pickUpCommodity[0].commodityName:"").toLowerCase().includes(filters.commodity.toLowerCase()) || (((sheet.deliveryCommodity && sheet.deliveryCommodity[0])?sheet.deliveryCommodity[0].commodityName:"").toLowerCase().includes(filters.commodity.toLowerCase())))) &&
              (!filters.isLoadVerified || ((sheet.isLoadVerified === false)))
            );
          });
        setFilteredLoads(filtered);
        
    }, [values.tripSheets, filters]);

    // Handle dropdown filter search
    const handleDropDownFilterChange = (name, selectedOption) => {
     setFilters((prevFilters) => ({
       ...prevFilters,
       [name]: selectedOption ? selectedOption.value : '',
     }));
    };


    // Handle search for loads
    const handleLoadSearch = (event) => {
        const name = event.target.name;
        const value = name === 'isLoadVerified' ? event.target.checked : event.target.value;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
          }));
    };

    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, [name]: value });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        reloadInvoice();
    };

    const updateInvoiceAfterSheetDeletion = (sheetId, data) => {
        setValues({ ...values, tripSheets: tripSheets.map(sheet => (sheet._id === sheetId ? data : sheet ))});
    }

    const downloadPDF = () => {
        const doc = new jsPDF('landscape');
        const systemDate = new Date();
       
        // Add content above the image
        doc.setFontSize(15);
        doc.setFont('helvetica', 'bold');
        doc.text(`Trip Sheet`, doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });

        doc.setFontSize(11);
        doc.setFont('helvetica', 'bold');
        doc.text(`Date:`, 10, 18);
        doc.text(`Report Duration:`, 10, 23);

        doc.setFont('helvetica', 'normal');
        doc.text(`${moment(systemDate).format('MMMM D, YYYY')}`, 22, 18);
        doc.text(`${moment(startDate).format('MMMM D, YYYY')} to ${moment(endDate).format('MMMM D, YYYY')}`, 43, 23);
        
        // Add the table using autoTable
        doc.autoTable({
            startY: 35,
            head: [[
                'Sr. No.', 'Shipping Date', 'Shipping Location', 'Ticket Number', 'Net Pounds', 
                'Receiving Date', 'Receiving Location', 'Ticket Number', 'Net Pounds',
                'Commodity','Truck #', 'Trailer #', 'Customer', 'Driver', 'Billable Wait Time (mins)', 'Wait Charge ($/hour)', 'Billable Wait Charge',
                'Net Unit', 'Rate Per Unit', 'Load Subtotal', 'Fuel S/C Rate', 'Fuel Amount', 'Status'
            ]],
            body: tripSheets.map((sheet, index) => ([
                index + 1,
                sheet.date ? ((sheet.date).substring(0, 10)) : "",
                getPickupLocation(sheet) || "",
                sheet.ticketNumberAtShipping ? sheet.ticketNumberAtShipping : "",
                (sheet.inBoundWeight || sheet.inBoundWeight === 0) ? sheet.inBoundWeight : "",

                (sheet.typeOfLoad && (sheet.typeOfLoad===311 || sheet.typeOfLoad===321)) ? "-" : (sheet.receivingDate ? ((sheet.receivingDate).substring(0, 10)) : ""),
                (sheet.typeOfLoad && (sheet.typeOfLoad===311 || sheet.typeOfLoad===321)) ? "-" : (getDeliveryLocation(sheet) || ""),
                (sheet.typeOfLoad && (sheet.typeOfLoad===311 || sheet.typeOfLoad===321)) ? "-" : (sheet.ticketNumberAtReceiving ? sheet.ticketNumberAtReceiving : ""),
                (sheet.typeOfLoad && (sheet.typeOfLoad===311 || sheet.typeOfLoad===321)) ? "-" : ((sheet.outBoundWeight || sheet.outBoundWeight === 0) ? sheet.outBoundWeight : ""),
                getPickupCommodity(sheet, true) || "",
                getTruckNo_2(sheet, true) || "",
                getTrailerNo_2(sheet, true) || "",
                getPickupCompany_2(sheet, true) || "",
                getDriverName(sheet, true) || "",
                sheet.isWaitingTimeWaivedOff ? "0" :(getBillableWaitingPeriod(sheet) || ""),
                getWaitRate(sheet, true) || "",
                sheet.isWaitingTimeWaivedOff ? "$0.00" :(getBillableWaitCharge(sheet, true) || ""),
                getNetUnit(sheet) || "",
                getRatePerUnit(sheet) || "",
                getLoadSubTotal(sheet, true) || "",
                (sheet.fuelRate || sheet.fuelRate === 0) ? `${sheet.fuelRate}%` : "",
                getFuelAmount(sheet, true) || "",
                (sheet.isDeliveryIncomplete ? "Rejected" :
                       (sheet.typeOfLoad && sheet.typeOfLoad === 311) ? "No Load" : 
                       (sheet.typeOfLoad && sheet.typeOfLoad === 321) ? "Yard Work" :
                       "Completed"
                )

            ])),
            theme: 'striped',
            styles: {
                fontSize: 6,
                lineColor: [0, 0, 0],  // Border color
                lineWidth: 0.2         // Border width
            },
           
            tableLineColor: [0, 0, 0],  // Outer border color
            tableLineWidth: 0.2         // Outer border width
        });
    
        // Add content below the table
        let finalY = doc.previousAutoTable.finalY + 10;
        const pageHeight = doc.internal.pageSize.getHeight();
    
        if (finalY + 30 > pageHeight) {
            doc.addPage();
            finalY = 20; // Reset finalY for new page
        }
            
        const filename = `Load_sheet_${moment(startDate).format('DDMMYYYY')}_${moment(endDate).format('DDMMYYYY')}.pdf`;
        doc.save(filename);
    };

    const downloadImagesPDF = async () => {
        setValues({ ...values, loadingModal: true });
        handleShowLoadingModal();
    
        const doc = new jsPDF('landscape');
        let startY = 5;
        let hasImage = false;
        let countOfImages = 0;
        
        // Fetch S3 images
        const fetchImagePromises = [];
        const imagesData = [];
        for (const [index, tripSheet] of tripSheets.entries()) {
            if (isS3IntegrationEnabled) {
                const imageFields = ['photo1Name', 'photo2Name', 'photo3Name', 'photo4Name', 'photo5Name', 'photo6Name'];
                imageFields.forEach((field, i) => {
                    if (tripSheet && tripSheet[field]) {
                        const promise = fetchImageFromS3(tripSheet._id, `tripPhoto${i + 1}`).then(async (url) => {
                            const data = await fetchAndCacheImage(url);
                            imagesData.push({data, tripsheet: index+1, count: i+1});
                        });
                        fetchImagePromises.push(promise);
                    }
                });
            }
    
            // Fetch MongoDB images
            if (isMongoDBImageReadEnabled) {
                for (let i = 1; i <= 6; i++) {
                    const photo = tripSheet[`photo${i}`];
                    if (photo && photo.data) {
                        const imgData = `data:${photo.contentType};base64,${photo.data.toString('base64')}`;
                        imagesData.push({data: imgData, tripsheet: index+1, count: i+1});
                    }
                }
            }
        }
    
        await Promise.all(fetchImagePromises);
    
        // Add images to the PDF
        if (imagesData.length > 0) {
            hasImage = true;
            countOfImages += imagesData.length;

            // Group images by trip sheet and sort them by trip sheet and count
            const groupedImages = imagesData.reduce((acc, imageData) => {
                const { tripsheet, count } = imageData;
                if (!acc[tripsheet]) {
                    acc[tripsheet] = [];
                }
                acc[tripsheet].push(imageData);
                return acc;
            }, {});

            // Sort the grouped images by trip sheet number
            const sortedTripSheets = Object.keys(groupedImages).sort((a, b) => a - b);

            for (const tripSheet of sortedTripSheets) {
                const images = groupedImages[tripSheet];
                // Sort images within each trip sheet by count
                images.sort((a, b) => a.count - b.count);

                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text(`Trip Sheet ${tripSheet}`, 10, startY);
                startY += 5;

                for (const [j, imageData] of images.entries()) {
                    const imgProps = doc.getImageProperties(imageData.data);
                    const pageWidth = doc.internal.pageSize.getWidth();
                    const pageHeight = doc.internal.pageSize.getHeight();
                    const imgMaxWidth = pageWidth * 0.88;
                    const imgMaxHeight = pageHeight * 0.88;

                    let imgWidth = imgMaxWidth;
                    let imgHeight = (imgProps.height * imgWidth) / imgProps.width;

                    if (imgHeight > imgMaxHeight) {
                        imgHeight = imgMaxHeight;
                        imgWidth = (imgProps.width * imgHeight) / imgProps.height;
                    }

                    const xPosition = (pageWidth - imgWidth) / 2;
                    const yPosition = startY + ((pageHeight - startY - imgHeight) / 2);

                    doc.text(`Image: ${j+1}`, 10, startY);
                    doc.addImage(imageData.data, 'JPEG', xPosition, yPosition, imgWidth, imgHeight);

                    doc.addPage();
                    startY = 5;
                }
            }
        }
            
        const lastPageNumber = doc.internal.getNumberOfPages();
        if (lastPageNumber > countOfImages) {
            doc.deletePage(doc.internal.getNumberOfPages());
        }
    
        hideLoadingModal();
        setValues({ ...values, loadingModal: false });
    
        if (!hasImage) {
            toastifyMessage("warning", "No images exist for the customer invoice");
            return;
        }
    
        const filename = `Load_photos_${moment(startDate).format('DDMMYYYY')}_${moment(endDate).format('DDMMYYYY')}.pdf`;
        doc.save(filename);
        
        // Clear Image cache
        clearImageCache();
    };

    const dateFilterForm = () => (
        <div className="date-filter-form">
            <div className="date-filter-row">
                <label className="text-light">Start Date</label>
                <input
                    onChange={handleChange('startDate')}
                    type="date"
                    className="form-control date-input"
                    value={startDate}
                    max={endDate}
                />
                <label className="text-light">End Date</label>
                <input
                    onChange={handleChange('endDate')}
                    type="date"
                    className="form-control date-input"
                    value={endDate}
                    min={startDate}
                />
                <button onClick={onSubmit} className="btn btn-outline-info submit-btn rounded">
                    Submit
                </button>
                <button
                    onClick={downloadPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download as PDF
                </button>
                <button
                    onClick={downloadImagesPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Photos
                </button>
            </div>
        </div>
    );

    // For loading modal
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const hideLoadingModal = () => setShowLoadingModal(false);
    const handleShowLoadingModal = () => setShowLoadingModal(true);

    // For Delete confirmation modal
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the load?");
    const [deleteTripSheetId, setDeleteTripSheetId] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false);
    const handleShowDeleteMessage = () => setShowDeleteMessage(true);

    // Delete Trip Sheet using the Admin privilege
    const deleteThisTripSheet = (tripSheetId) => {
        setValues({ ...values, error: "", loadingModal: true});
        deletetripsheet(tripSheetId, admin._id, token)
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data, loadingModal: "" });
                    toastifyMessage("error", 'Error deleting the trip sheet');
                } else {
                    toastifyMessage("success", "Trip Sheet deleted successfully");
         
                    // To close the modal & reload the values
                    handleCancelDeletion();
                    setValues({ ...values, error: "", loadingModal: "", tripSheets: (tripSheets.filter(sheet => (sheet._id !== tripSheetId)))});
                }
            })
            .catch(err => {
                console.log('Error deleting the trip sheet:', err);
                setValues({ ...values, error: "Error deleting the trip sheet", loadingModal: "" });
                toastifyMessage("error", 'Error deleting the trip sheet');
            });
    };

    return (
        <Base title="Loads Dashboard" description="Manage/Edit/Delete">
            <Link className="btn btn-info rounded" to={`/admin/dashboard`}>
                <span className="">Admin Home</span>
            </Link>
            <div className="trip-dashboard">
                <div className="trip-header">
                    <h2 className="text-center text-success my-3 mt-5 mb-5" style={{ fontFamily: 'Englebert' }}>View Loads</h2>
                    {dateFilterForm()}
                </div>
                <div className="invoice-content">
                    {error && <h4 className="error-message">Error fetching the loads</h4>}
                    {isLoaded && !error && tripSheets.length > 0 && (
                         <>
                            <div className="filters">
                                <div className="filter-item">
                                     <p style={{ marginBottom: '10px' }}>Filter using Ticket #:</p>
                                     <input
                                         type="text"
                                         style={{
                                            width: '100%',
                                            maxWidth: '300px',
                                            padding: '8px',
                                            boxSizing: 'border-box'
                                          }}
                                         placeholder="Filter the loads using the ticket #"
                                         name="ticketNumber"
                                         value={filters.ticketNumber}
                                         onChange={handleLoadSearch}
                                     />
                                </div>
                                <div className="filter-item">
                                     <p style={{ marginBottom: '10px' }}>Filter using Location:</p>
                                     <input
                                         type="text"
                                         style={{
                                            width: '100%',
                                            maxWidth: '300px',
                                            padding: '8px',
                                            boxSizing: 'border-box'
                                          }}
                                         placeholder="Filter the loads using the location"
                                         name="location"
                                         value={filters.location}
                                         onChange={handleLoadSearch}
                                     />
                                </div>
                                <div className="filter-item">
                                     <p style={{ marginBottom: '10px' }}>Filter using Customer:</p>
                                     <input
                                         type="text"
                                         style={{
                                            width: '100%',
                                            maxWidth: '300px',
                                            padding: '8px',
                                            boxSizing: 'border-box'
                                          }}
                                         placeholder="Filter the loads using the customer"
                                         name="customer"
                                         value={filters.customer}
                                         onChange={handleLoadSearch}
                                     />
                                </div>
                                <div className="filter-item">
                                     <p style={{ marginBottom: '10px' }}>Filter using Driver name:</p>
                                     <input
                                         type="text"
                                         style={{
                                            width: '100%',
                                            maxWidth: '300px',
                                            padding: '8px',
                                            boxSizing: 'border-box'
                                          }}
                                         placeholder="Filter the loads using the driver"
                                         name="driver"
                                         value={filters.driver}
                                         onChange={handleLoadSearch}
                                     />
                                </div>
                                <div className="filter-item">
                                     <p style={{ marginBottom: '10px' }}>Filter using Commodity:</p>
                                     <input
                                         type="text"
                                         style={{
                                            width: '100%',
                                            maxWidth: '300px',
                                            padding: '8px',
                                            boxSizing: 'border-box'
                                          }}
                                         placeholder="Filter the loads using the commodity name"
                                         name="commodity"
                                         value={filters.commodity}
                                         onChange={handleLoadSearch}
                                     />
                                </div>
                                <div className="filter-item">
                                        <label className="text-light" style={{ marginRight: '10px', marginBottom: '10px' }}>
                                            Show only unverified loads
                                        </label>
                                        <input
                                          type="checkbox"
                                          name="isLoadVerified"
                                          checked={filters.isLoadVerified}
                                          onChange={handleLoadSearch}
                                          style={{
                                            width: '30px',
                                            height: '30px',
                                            cursor: 'pointer',
                                          }}
                                        />
                                </div>
                            </div>
                             <div className="trip-sheet-table">
                                 <LoadsTableView tripSheets={filteredLoads} editURL={`/admin/update/partner/tripsheet/`} isAggregated={true} updateSheet={updateInvoice} flipLoadVerificationSheetStatus={flipLoadVerificationSheetStatus} flipWaitingWaiverSheetStatus={flipWaitingWaiverSheetStatus} setDeleteTripSheetId={setDeleteTripSheetId} handleShowDeleteMessage={handleShowDeleteMessage}/>
                             </div>
                        </>
                    )}
                </div>
            </div>
            {LoadingScreen(loading)}
            <PreparingDownload showModal={showLoadingModal} hideModal={hideLoadingModal}  isLoading={loadingModal} />
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={deleteThisTripSheet} id={deleteTripSheetId} message={deleteMessage} loadingModal={loadingModal}/>
            <ToastifyMessage />
        </Base>
    );
}