import React, { useState, useEffect } from 'react';
import Base from '../../core/Base';
import { Link, useParams } from 'react-router-dom';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { gettripsheetsfordriver, deletetripsheet, fetchImageFromS3 } from '../helper/adminapicall';
import LoadingScreen from '../../core/helper/LoadingScreen';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import TripSheetTableView from '../../core/helper/TripSheetTableView';
import PreparingDownload from '../../core/helper/PreparingDownload';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import '../AdminSideSheet.css';
import { getTruckNo_2, getTrailerNo_2, getPickupCompany_2, getDeliveryLocation, getPickupLocation, getPickupCommodity, getDate } from '../../core/helper/helperFunctions';
import { S3_INTEGRATION_ENABLED, ENABLE_MONGO_READ_IMAGE } from "../../backend";
import { fetchAndCacheImage, clearImageCache } from '../../core/helper/Utils/DownloadUtils';

export default function ViewDriverTripSheets() {

    const { admin, token } = isAuthenticated();
    const { driverId } = useParams();

    // Kill switch based implementation for S3
    const s3_integration_enabled_prop = (S3_INTEGRATION_ENABLED).toString().toLowerCase();
    const isS3IntegrationEnabled = s3_integration_enabled_prop==="true" ? true : false;

    // Kill switch to disable images reads from mongo database
    const enable_mongo_read_image_prop = (ENABLE_MONGO_READ_IMAGE).toString().toLowerCase();
    const isMongoDBImageReadEnabled = enable_mongo_read_image_prop==="true" ? true : false;

    // For date filters : end date is the current system date
    const [values, setValues] = useState({
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loading: "",
        loadingModal: "",
        error: "",
        isLoaded: "",
        tripSheets: [],
        firstName: "",
        lastName: ""
    });

    const { startDate, endDate, loading, loadingModal, error, isLoaded, tripSheets, firstName, lastName } = values;

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: "" })
        gettripsheetsfordriver(admin._id, driverId, { startDate, endDate }, token).then(data => {
            if (data.error) {
                setValues({ ...values, error: data, loading: "", isLoaded: "" })
                toastifyMessage("error", "Error fetching the trip sheets");
            } else {
                const updatedValues = {
                    ...values,
                    tripSheets: data,
                    loading: "",
                    isLoaded: true
                };
                if (data[0] && data[0].driverNo[0]) {
                    updatedValues.firstName = data[0].driverNo[0].firstName;
                    updatedValues.lastName = data[0].driverNo[0].lastName;
                }
                setValues(updatedValues);
                if(data.length===0){
                    toastifyMessage("warning", "No trip sheet found");
                    }
            }
        })
        .catch(err => {
            console.log('Error loading the trip sheets:', err);
            setValues({ ...values, error: "Error loading the trip sheets", loading: "" });
            toastifyMessage("error", 'Error loading the trip sheets');
        });
    }

    useEffect(() => {
        preload();
    }, []);

    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, [name]: value });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        preload();
    };

    const downloadPDF = () => {
        const doc = new jsPDF('landscape');
        const pageHeight = doc.internal.pageSize.getHeight();

        // Add content above the image
        doc.setFontSize(15);
        doc.setFont('helvetica', 'bold');
        doc.text(`Trip Sheet`, doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });

        doc.setFontSize(11);
        doc.setFont('helvetica', 'bold');
        doc.text(`Driver:`, 10, 18);
        doc.text(`Report Duration:`, 10, 23);

        doc.setFont('helvetica', 'normal');
        doc.text(`${firstName} ${lastName}`, 24, 18);
        doc.text(`${moment(startDate).format('MMMM D, YYYY')} to ${moment(endDate).format('MMMM D, YYYY')}`, 43, 23);

        const tableColumns = ["Sr. No.", "Pickup Date", "Pickup Scale In", "Pickup Scale Out", "Customer", "Pickup Location", "Ticket #", "Inbound Weight", "Delivery Date", "Delivery Scale In", "Delivery Scale Out", "Drop Location", "Ticket #", "Outbound Weight", "Deductions", "Commodity","Truck", "Trailer", "Status"];

        const tableRows = tripSheets.map((tripSheet, index) => {
            const row = [
                index + 1,
                tripSheet.date ? getDate(tripSheet.date) : "",
                tripSheet.pickUpTimeIn || "",
                tripSheet.pickUpTimeOut || "",
                getPickupCompany_2(tripSheet, true) || "",
                getPickupLocation(tripSheet) || "",
                tripSheet.ticketNumberAtShipping || "",
                (tripSheet.inBoundWeight || tripSheet.inBoundWeight===0) ? tripSheet.inBoundWeight : "",
                (tripSheet.typeOfLoad && (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321)) ? "-" : (tripSheet.receivingDate ? getDate(tripSheet.receivingDate) : ""),
                (tripSheet.typeOfLoad && (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321)) ? "-" : (tripSheet.deliveryTimeIn || ""),
                (tripSheet.typeOfLoad && (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321)) ? "-" : (tripSheet.deliveryTimeOut || ""),
                (tripSheet.typeOfLoad && (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321)) ? "-" : (getDeliveryLocation(tripSheet) || ""),
                (tripSheet.typeOfLoad && (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321)) ? "-" : (tripSheet.ticketNumberAtReceiving || ""),
                (tripSheet.typeOfLoad && (tripSheet.typeOfLoad===311 || tripSheet.typeOfLoad===321)) ? "-" : ((tripSheet.outBoundWeight || tripSheet.outBoundWeight===0) ? tripSheet.outBoundWeight : ""),
                (tripSheet.deductions || tripSheet.deductions===0) ? tripSheet.deductions : "",
                getPickupCommodity(tripSheet, true) || "",
                getTruckNo_2(tripSheet, true) || "",
                getTrailerNo_2(tripSheet, true) || "",
                tripSheet.isDeliveryIncomplete ?
                    "Rejected" :  
                    (
                        (tripSheet.typeOfLoad && tripSheet.typeOfLoad === 311) ? "No Load" :
                        (tripSheet.typeOfLoad && tripSheet.typeOfLoad === 321) ? "Yard Work" :
                        "Completed"
                    )
            ];
            return row;
        });

        doc.autoTable({
            startY: 35,
            head: [tableColumns],
            body: tableRows,
            theme: 'striped',
            styles: {
                fontSize: 6,
                lineColor: [0, 0, 0],  // Border color
                lineWidth: 0.2         // Border width
            },
           
            tableLineColor: [0, 0, 0],  // Outer border color
            tableLineWidth: 0.2         // Outer border width
        });
        
        const filename = (firstName && lastName) 
        ? `Tripsheet_${firstName}_${lastName}_${moment(startDate).format('DDMMYYYY')}_${moment(endDate).format('DDMMYYYY')}.pdf`
        : `Tripsheet_${moment(startDate).format('DDMMYYYY')}_${moment(endDate).format('DDMMYYYY')}.pdf`;
        doc.save(filename);
    };

    const downloadImagesPDF = async () => {
        setValues({ ...values, loadingModal: true });
        handleShowLoadingModal();
    
        const doc = new jsPDF('landscape');
        let startY = 5;
        let hasImage = false;
        let countOfImages = 0;
    
        // Fetch S3 images
        const fetchImagePromises = [];
        const imagesData = [];
        for (const [index, tripSheet] of tripSheets.entries()) {
            if (isS3IntegrationEnabled) {
                const imageFields = ['photo1Name', 'photo2Name', 'photo3Name', 'photo4Name', 'photo5Name', 'photo6Name'];
                imageFields.forEach((field, i) => {
                    if (tripSheet && tripSheet[field]) {
                        const promise = fetchImageFromS3(tripSheet._id, `tripPhoto${i + 1}`).then(async (url) => {
                            const data = await fetchAndCacheImage(url);
                            imagesData.push({data, tripsheet: index+1, count: i+1});
                        });
                        fetchImagePromises.push(promise);
                    }
                });
            }
    
            // Fetch MongoDB images
            if (isMongoDBImageReadEnabled) {
                for (let i = 1; i <= 6; i++) {
                    const photo = tripSheet[`photo${i}`];
                    if (photo && photo.data) {
                        const imgData = `data:${photo.contentType};base64,${photo.data.toString('base64')}`;
                        imagesData.push({data: imgData, tripsheet: index+1, count: i+1});
                    }
                }
            }
        }
    
        await Promise.all(fetchImagePromises);
    
        // Add images to the PDF
        if (imagesData.length > 0) {
            hasImage = true;
            countOfImages += imagesData.length;

            // Group images by trip sheet and sort them by trip sheet and count
            const groupedImages = imagesData.reduce((acc, imageData) => {
                const { tripsheet, count } = imageData;
                if (!acc[tripsheet]) {
                    acc[tripsheet] = [];
                }
                acc[tripsheet].push(imageData);
                return acc;
            }, {});

            // Sort the grouped images by trip sheet number
            const sortedTripSheets = Object.keys(groupedImages).sort((a, b) => a - b);

            for (const tripSheet of sortedTripSheets) {
                const images = groupedImages[tripSheet];
                // Sort images within each trip sheet by count
                images.sort((a, b) => a.count - b.count);
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text(`Trip Sheet ${tripSheet}`, 10, startY);
                startY += 5;
    
                for (const [j, imageData] of images.entries()) {
                    const imgProps = doc.getImageProperties(imageData.data);
                    const pageWidth = doc.internal.pageSize.getWidth();
                    const pageHeight = doc.internal.pageSize.getHeight();
                    const imgMaxWidth = pageWidth * 0.88;
                    const imgMaxHeight = pageHeight * 0.88;
    
                    let imgWidth = imgMaxWidth;
                    let imgHeight = (imgProps.height * imgWidth) / imgProps.width;
    
                    if (imgHeight > imgMaxHeight) {
                        imgHeight = imgMaxHeight;
                        imgWidth = (imgProps.width * imgHeight) / imgProps.height;
                    }
    
                    const xPosition = (pageWidth - imgWidth) / 2;
                    const yPosition = startY + ((pageHeight - startY - imgHeight) / 2);
    
                    doc.text(`Image: ${j+1}`, 10, startY);
                    doc.addImage(imageData.data, 'JPEG', xPosition, yPosition, imgWidth, imgHeight);
    
                    doc.addPage();
                    startY = 5;
                }
            }
        }

        const lastPageNumber = doc.internal.getNumberOfPages();
        if (lastPageNumber > countOfImages) {
            doc.deletePage(doc.internal.getNumberOfPages());
        }
    
        hideLoadingModal();
        setValues({ ...values, loadingModal: false });
    
        if (!hasImage) {
            toastifyMessage("warning", "No images exist for the customer invoice");
            return;
        }
    
        const filename = (firstName && lastName)
            ? `Tripsheet_Photos_${firstName}_${lastName}_${moment(startDate).format('DDMMYYYY')}_${moment(endDate).format('DDMMYYYY')}.pdf`
            : `Tripsheet_Photos_${moment(startDate).format('DDMMYYYY')}_${moment(endDate).format('DDMMYYYY')}.pdf`;
        doc.save(filename);

        // Clear Image cache
        clearImageCache();
    };

    const dateFilterForm = () => (
        <div className="date-filter-form">
            <div className="date-filter-row">
                <label className="text-light">Start Date</label>
                <input
                    onChange={handleChange('startDate')}
                    type="date"
                    className="form-control date-input"
                    value={startDate}
                    max={endDate}
                />
                <label className="text-light">End Date</label>
                <input
                    onChange={handleChange('endDate')}
                    type="date"
                    className="form-control date-input"
                    value={endDate}
                    min={startDate}
                />
                <button onClick={onSubmit} className="btn btn-outline-info submit-btn rounded">
                    Submit
                </button>
                <button
                    onClick={downloadPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Sheet
                </button>
                <button
                    onClick={downloadImagesPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Photos
                </button>
            </div>
        </div>
    );

    // For Delete confirmation modal
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the trip sheet?");
    const [deleteTripSheetId, setDeleteTripSheetId] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false);
    const handleShowDeleteMessage = () => setShowDeleteMessage(true);

    // Delete Trip Sheet using the Admin privilege
    const deleteThisTripSheet = (tripSheetId) => {
        setValues({ ...values, error: "", loadingModal: true});
        deletetripsheet(tripSheetId, admin._id, token)
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data, loadingModal: "" });
                    toastifyMessage("error", 'Error deleting the trip sheet');
                } else {
                    toastifyMessage("success", "Trip Sheet deleted successfully");
         
                    // To close the modal & reload the values
                    handleCancelDeletion();
                    setValues({ ...values, error: "", loadingModal: "", tripSheets: (tripSheets.filter(sheet => (sheet._id !== tripSheetId)))});
                }
            })
            .catch(err => {
                console.log('Error deleting the trip sheet:', err);
                setValues({ ...values, error: "Error deleting the trip sheet", loadingModal: "" });
                toastifyMessage("error", 'Error deleting the trip sheet');
            });
    };

    // For loading modal
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const hideLoadingModal = () => setShowLoadingModal(false);
    const handleShowLoadingModal = () => setShowLoadingModal(true);

    return (
        <Base title="Trip Sheets Dashboard" description="View/Edit/Delete">
            <Link className="btn btn-info rounded" to={`/admin/dashboard`}>
                <span className="">Admin Home</span>
            </Link>
            <div className="trip-dashboard">
                <div className="trip-header">
                    <h2 className="text-center text-success my-3 mt-5 mb-5" style={{ fontFamily: 'Englebert' }}> {(isLoaded && tripSheets[0] && tripSheets[0].driverNo) ? "Driver - " + tripSheets[0].driverNo[0].firstName + " " + tripSheets[0].driverNo[0].lastName : "Trip Sheet"} </h2>
                    {dateFilterForm()}
                </div>

                <div className="invoice-content">
                    {error && <h4 className="error-message">Error fetching the trip sheets</h4>}
                    {isLoaded && !error && tripSheets.length > 0 && (
                        <div id="trip-sheet-table">
                            <TripSheetTableView tripSheets={tripSheets} editURL={`/admin/update/driver/tripsheet/`} isAggregated={true} isAdmin={true} isDriver={false} setDeleteTripSheetId={setDeleteTripSheetId} handleShowDeleteMessage={handleShowDeleteMessage} />
                        </div>
                    )}
                </div>
            </div>
            {LoadingScreen(loading)}
            <PreparingDownload showModal={showLoadingModal} hideModal={hideLoadingModal}  isLoading={loadingModal} />
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={deleteThisTripSheet} id={deleteTripSheetId} message={deleteMessage} loadingModal={loadingModal}/>
            <ToastifyMessage />
        </Base>
    )
}
