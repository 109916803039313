import React, { useState, useEffect } from 'react';
import Base from '../../core/Base';
import { Link, useParams, useLocation } from 'react-router-dom';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { gettripsheetsforclient, getmerchant, getcommodities, getlocations, fetchImageFromS3 } from '../helper/adminapicall';
import LoadingScreen from '../../core/helper/LoadingScreen';
import PreparingDownload from '../../core/helper/PreparingDownload';
import InvoiceTableView from '../../core/helper/InvoiceTableView';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { downloadExcel } from "react-export-table-to-excel";
import '../AdminSideSheet.css';
import { 
    getTruckNo_2, getTrailerNo_2, getDriverName, getPickupCompany_2, getDeliveryLocation, getPickupLocation, getPickupCommodity, getDate,
    getBillableWaitingPeriod, getWaitRate, getBillableWaitCharge, getNetUnit, getRatePerUnit, getLoadSubTotal, getFuelAmount
 } from '../../core/helper/helperFunctions';
import Logo from "../../images/ttms_logo_2.jpg";
import { S3_INTEGRATION_ENABLED, ENABLE_MONGO_READ_IMAGE, ENABLE_TEXTDROPDOWN_FILTER } from "../../backend";
import { fetchAndCacheImage, clearImageCache } from '../../core/helper/Utils/DownloadUtils';
import FilterDropdown from '../../core/helper/FilterWithDropdown/FilterDropdown';

let globalPartnerName = "";

// Total Amounts
let globalWaitChargeTotal = 0;
let globalInvoiceSubTotal = 0;
let globalFSCTotal = 0;
let globalInvoiceNet = 0;
let globalHSTTotal = 0;
let globalInvoiceTotal = 0;

export default function ViewClientInvoice() {

    const { admin, token } = isAuthenticated();
    const { partnerId } = useParams();
    const location = useLocation();

    // Kill switch based implementation for S3
    const s3_integration_enabled_prop = (S3_INTEGRATION_ENABLED).toString().toLowerCase();
    const isS3IntegrationEnabled = s3_integration_enabled_prop==="true" ? true : false;

    // Kill switch to disable images reads from mongo database
    const enable_mongo_read_image_prop = (ENABLE_MONGO_READ_IMAGE).toString().toLowerCase();
    const isMongoDBImageReadEnabled = enable_mongo_read_image_prop==="true" ? true : false;

    // Kill switch based implementation for text based dropdown filters
    const enable_text_dropdown_filter_prop = (ENABLE_TEXTDROPDOWN_FILTER).toString().toLowerCase();
    const isTextDropdownFilterEnabled = enable_text_dropdown_filter_prop==="true" ? true : false;

    // Extracting URL parameters
    const query = new URLSearchParams(location.search);
    globalPartnerName = query.get('partnerName');

    // For date filters: end date is the current system date
    const [values, setValues] = useState({
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loading: "",
        loadingModal: "",
        error: "",
        isLoaded: "",
        client: "",
        billingAddress: "",
        billingProvince: "",
        tripSheets: [],
        commodityOptions: [],
        locationOptions: []        
    });

    // Filter state
    const [filters, setFilters] = useState({
         location: '',
         ticketNumber: '',
         commodity: '',
       });
    const [filteredLoads, setFilteredLoads] = useState([]);

    const { startDate, endDate, loading, loadingModal, error, isLoaded, tripSheets, commodityOptions, locationOptions, client, billingAddress, billingProvince } = values;

    const preload = () => {
        setValues({ ...values, error: "", loading: true, isLoaded: "" })
        getmerchant(partnerId, admin._id, token).then(data1 => {
            if(data1.error) {
                setValues({ ...values, error: data1, loading: "", isLoaded: ""})
                toastifyMessage("error", "Error fetching the customer details");
            }else{
                gettripsheetsforclient(admin._id, partnerId, {startDate, endDate}, token).then(data2 => {
                    if(data2.error) {
                        setValues({ ...values, error: data2, loading: "", isLoaded: ""})
                        toastifyMessage("error", "Error fetching the invoice");
                    }else{
                        getcommodities(admin._id, token).then(data3 => {
                            if(data3.error) {
                                setValues({ ...values, error: data3, loading: "", isLoaded: ""})
                                toastifyMessage("error", "Error fetching the invoice");
                            }else{
                                getlocations(admin._id, token).then(data4 => {
                                    if(data4.error) {
                                        setValues({ ...values, error: data4, loading: "", isLoaded: ""})
                                        toastifyMessage("error", "Error fetching the invoice");
                                    }else{
                                        setValues({ 
                                            ...values, 
                                            tripSheets:data2, 
                                            client:data1, 
                                            billingAddress: ((data1.streetAddress?data1.streetAddress:"") + " " + (data1.city?data1.city:"")),
                                            billingProvince: ((data1.state?data1.state:"") + " " + (data1.country?data1.country:"") + " " + (data1.pincode?data1.pincode:"")),
                                            commodityOptions: data3.map((c) => ({
                                                value: `${c.commodityName}`,
                                                label: `${c.commodityName}`,
                                              })),
                                            locationOptions: data4.map((l) => ({
                                                value: `${l.location}`,
                                                label: `${l.location}`,
                                              })),
                                            error: "",
                                            loading: "",
                                            isLoaded: true});
                
                                            // Invoice calculations
                                            globalWaitChargeTotal = (data2.reduce((total, sheet) => total + parseFloat(getBillableWaitCharge(sheet) || 0), 0)).toFixed(2);
                                            globalInvoiceSubTotal = (data2.reduce((total, sheet) => total + parseFloat(getLoadSubTotal(sheet) || 0), 0)).toFixed(2);
                                            globalFSCTotal = data2.reduce((total, sheet) => total + parseFloat(getFuelAmount(sheet) || 0), 0).toFixed(2);
                                            globalInvoiceNet = (parseFloat(globalInvoiceSubTotal) + parseFloat(globalFSCTotal) + parseFloat(globalWaitChargeTotal)).toFixed(2);
                                            globalHSTTotal = (parseFloat(globalInvoiceNet) * 0.13).toFixed(2);
                                            globalInvoiceTotal = (parseFloat(globalInvoiceNet) + parseFloat(globalHSTTotal)).toFixed(2);
                                        if(data2.length===0){
                                            toastifyMessage("warning", "No data found");
                                        }
                                    }
                                })
                                .catch(err => {
                                    console.log('Error loading the customer invoice:', err);
                                    setValues({ ...values, error: "Error loading the customer invoice", loading: "" });
                                    toastifyMessage("error", 'Error loading the customer invoice');
                                });
                            }
                        })
                        .catch(err => {
                            console.log('Error loading the customer invoice:', err);
                            setValues({ ...values, error: "Error loading the customer invoice", loading: "" });
                            toastifyMessage("error", 'Error loading the customer invoice');
                        });
                    }
                })
                .catch(err => {
                    console.log('Error loading the customer invoice:', err);
                    setValues({ ...values, error: "Error loading the customer invoice", loading: "" });
                    toastifyMessage("error", 'Error loading the customer invoice');
                });
            }
        })
        .catch(err => {
            console.log('Error loading the customer invoice:', err);
            setValues({ ...values, error: "Error loading the customer invoice", loading: "" });
            toastifyMessage("error", 'Error loading the customer invoice');
        });
    }

    const reloadInvoice = () => {
        setValues({ ...values, error: "", loading: true, isLoaded: "" })
        gettripsheetsforclient(admin._id, partnerId, {startDate, endDate}, token).then(data1 => {
            if(data1.error) {
                setValues({ ...values, error: data1, loading: "", isLoaded: ""})
                toastifyMessage("error", "Error fetching the invoice");
            }else{
                setValues({ 
                    ...values, 
                    tripSheets:data1,
                    error: "", 
                    loading: "", 
                    isLoaded: true});

                    // Invoice calculations
                    globalWaitChargeTotal = (data1.reduce((total, sheet) => total + parseFloat(getBillableWaitCharge(sheet) || 0), 0)).toFixed(2);
                    globalInvoiceSubTotal = (data1.reduce((total, sheet) => total + parseFloat(getLoadSubTotal(sheet) || 0), 0)).toFixed(2);
                    globalFSCTotal = data1.reduce((total, sheet) => total + parseFloat(getFuelAmount(sheet) || 0), 0).toFixed(2);
                    globalInvoiceNet = (parseFloat(globalInvoiceSubTotal) + parseFloat(globalFSCTotal) + parseFloat(globalWaitChargeTotal)).toFixed(2);
                    globalHSTTotal = (parseFloat(globalInvoiceNet) * 0.13).toFixed(2);
                    globalInvoiceTotal = (parseFloat(globalInvoiceNet) + parseFloat(globalHSTTotal)).toFixed(2);
                if(data1.length===0){
                    toastifyMessage("warning", "No data found");
                }
            }
        })
        .catch(err => {
            console.log('Error loading the customer invoice:', err);
            setValues({ ...values, error: "Error loading the customer invoice", loading: "" });
            toastifyMessage("error", 'Error loading the customer invoice');
        });
    }

    const updateInvoice = (id, data1, effectiveDate) => {
        // Update the tripSheets array in the state
        setValues(prevValues => ({
            ...prevValues,
            tripSheets: prevValues.tripSheets.map(sheet => {
                const shouldUpdate = 
                    (sheet._id === id) ||
                    (sheet.pickUpLocation === data1.pickUpLocation &&
                     sheet.deliveryLocation === data1.deliveryLocation &&
                     sheet.pickUpCompany[0]._id === data1.pickUpCompany._id &&
                     moment(sheet.date).isSameOrAfter(moment(effectiveDate))
                    );
    
                return shouldUpdate ? {
                    ...sheet,
                    ratePerUnit: data1.ratePerUnit || data1.ratePerUnit === 0 ? data1.ratePerUnit : sheet.ratePerUnit,
                    loadSubTotal: data1.loadSubTotal || data1.loadSubTotal === 0 ? data1.loadSubTotal : sheet.loadSubTotal,
                    ifFlatRate: (data1.ifFlatRate?true:false)
                } : sheet;
            })
        }));
    }

    const flipSheetStatus = (id, status) => {
         // Update the tripSheets array in the state
         setValues(prevValues => ({
            ...prevValues,
            tripSheets: prevValues.tripSheets.map(
                sheet => sheet._id === id ? { ...sheet, isLoadVerified: status } : sheet
            )
        }));
    }

    const reCalculateInvoice = () => {
        // Invoice calculations
        globalWaitChargeTotal = (tripSheets.reduce((total, sheet) => total + parseFloat(getBillableWaitCharge(sheet) || 0), 0)).toFixed(2);
        globalInvoiceSubTotal = (tripSheets.reduce((total, sheet) => total + parseFloat(getLoadSubTotal(sheet) || 0), 0)).toFixed(2);
        globalFSCTotal = tripSheets.reduce((total, sheet) => total + parseFloat(getFuelAmount(sheet) || 0), 0).toFixed(2);
        globalInvoiceNet = (parseFloat(globalInvoiceSubTotal) + parseFloat(globalFSCTotal) + parseFloat(globalWaitChargeTotal)).toFixed(2);
        globalHSTTotal = (parseFloat(globalInvoiceNet) * 0.13).toFixed(2);
        globalInvoiceTotal = (parseFloat(globalInvoiceNet) + parseFloat(globalHSTTotal)).toFixed(2);
    }

    // Preload the invoice
    useEffect(() => {
        preload();
    }, []);

     // Recalculate invoice totals whenever tripSheets state changes
     useEffect(() => {
        reCalculateInvoice();
    }, [values.tripSheets]);

    // Updates the filtered list whenever the tripsheets are updated
    useEffect(() => {
        const filtered = tripSheets.filter((sheet) => {
            return (
              (!filters.location || ((sheet.pickUpLocation?sheet.pickUpLocation:"").toLowerCase().includes(filters.location.toLowerCase()) || ((sheet.deliveryLocation?sheet.deliveryLocation:"").toLowerCase().includes(filters.location.toLowerCase())))) &&
              (!filters.ticketNumber || ((sheet.ticketNumberAtShipping?sheet.ticketNumberAtShipping:"").toLowerCase().includes(filters.ticketNumber.toLowerCase()) || ((sheet.ticketNumberAtReceiving?sheet.ticketNumberAtReceiving:"").toLowerCase().includes(filters.ticketNumber.toLowerCase())))) &&
              (!filters.commodity || (((sheet.pickUpCommodity && sheet.pickUpCommodity[0])?sheet.pickUpCommodity[0].commodityName:"").toLowerCase().includes(filters.commodity.toLowerCase()) || (((sheet.deliveryCommodity && sheet.deliveryCommodity[0])?sheet.deliveryCommodity[0].commodityName:"").toLowerCase().includes(filters.commodity.toLowerCase()))))
            );
          });
        setFilteredLoads(filtered);
        
    }, [values.tripSheets, filters]);

    // Handle filter search for driver
    const handleDropDownFilterChange = (name, selectedOption) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: selectedOption ? selectedOption.value : '',
        }));
    };

    // Handle search for loads
    const handleLoadSearch = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
          }));
    };

    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, [name]: value });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        reloadInvoice();
    };

    const updateInvoiceAfterSheetDeletion = (sheetId, data) => {
        setValues({ ...values, tripSheets: tripSheets.map(sheet => (sheet._id === sheetId ? data : sheet ))});
    }

    const downloadPDF = () => {
        const doc = new jsPDF('landscape');
        const systemDate = new Date(); // Get the current system date
    
        // Add company logo
        const logo = Logo; 
        doc.addImage(logo, 'JPG', 10, 10, 55, 15); 
    
        // Add company address
        doc.setFontSize(11);
        doc.setFont('helvetica', 'normal');
        doc.text('Triple Trans Mill Services Ltd.', 10, 35);
        doc.text('325 Masters Dr, Woodstock, ON N4T0L2', 10, 40);
        doc.text('Dispatch: (647) 774-9013 (778) 891-5781', 10, 45);
        doc.text('invoicing@tripletrans.ca', 10, 50);
        doc.text('Admin@tripletrans.ca', 10, 55);
    
        // Add invoice text on the right side
        doc.setFontSize(21);
        doc.setFont('times', 'bold'); // fonts like helvetica, times, symbol, zapfdingbats, courier
        doc.text('INVOICE', doc.internal.pageSize.getWidth() - 60, 20, { align: 'right' });
    
        // Add other invoice details
        doc.setFontSize(13);
        doc.setFont('helvetica', 'bold');
        doc.text('Customer', (doc.internal.pageSize.getWidth() / 2)-30, 35);
    
        doc.setFontSize(11);
        doc.setFont('helvetica', 'normal');
        doc.text(`${globalPartnerName}`,(doc.internal.pageSize.getWidth() / 2) -30, 40);
        doc.text(`${billingAddress}`, (doc.internal.pageSize.getWidth() / 2) -30, 45);
        doc.text(`${billingProvince}`, (doc.internal.pageSize.getWidth() / 2) -30, 50);
    
        doc.text(`Invoice Date: ${moment(systemDate).format('MMMM D, YYYY')}`, doc.internal.pageSize.getWidth() - 107, 35);
        doc.text(`Report Duration: ${moment(startDate).format('MMMM D, YYYY')} to ${moment(endDate).format('MMMM D, YYYY')}`, doc.internal.pageSize.getWidth() - 107, 40);
    
        // Add the table using autoTable
        doc.autoTable({
            startY: 65,
            head: [[
                'Sr. No.', 'Shipping Date', 'Shipping Location', 'Ticket Number', 'Net Pounds', 
                'Receiving Date', 'Receiving Location', 'Ticket Number', 'Net Pounds', 
                'Commodity','Truck Number', 'Trailer Number', 'Customer', 'Driver', 'Billable Wait Time (mins)', 'Wait Charge ($/hour)', 'Billable Wait Charge',
                'Net Unit', 'Rate Per Unit', 'Load Subtotal', 'Fuel S/C Rate', 'Fuel Amount'
            ]],
            body: tripSheets.map((sheet, index) => ([
                index + 1,
                sheet.date ? getDate(sheet.date) : "",
                getPickupLocation(sheet) || "",
                sheet.ticketNumberAtShipping ? sheet.ticketNumberAtShipping : "",
                (sheet.inBoundWeight || sheet.inBoundWeight === 0) ? sheet.inBoundWeight : "",
                (sheet.typeOfLoad && (sheet.typeOfLoad===311 || sheet.typeOfLoad===321)) ? "-" : (sheet.receivingDate ? getDate(sheet.receivingDate) : ""),
                (sheet.typeOfLoad && (sheet.typeOfLoad===311 || sheet.typeOfLoad===321)) ? "-" : (getDeliveryLocation(sheet) || ""),
                (sheet.typeOfLoad && (sheet.typeOfLoad===311 || sheet.typeOfLoad===321)) ? "-" : (sheet.ticketNumberAtReceiving ? sheet.ticketNumberAtReceiving : ""),
                (sheet.typeOfLoad && (sheet.typeOfLoad===311 || sheet.typeOfLoad===321)) ? "-" : ((sheet.outBoundWeight || sheet.outBoundWeight === 0) ? sheet.outBoundWeight : ""),
                getPickupCommodity(sheet, true) || "",
                getTruckNo_2(sheet, true) || "",
                getTrailerNo_2(sheet, true) || "",
                getPickupCompany_2(sheet, true) || "",
                getDriverName(sheet, true) || "",
                sheet.isWaitingTimeWaivedOff ? "0" :(getBillableWaitingPeriod(sheet) || ""),
                getWaitRate(sheet, true) || "",
                sheet.isWaitingTimeWaivedOff ? "$0.00" :(getBillableWaitCharge(sheet, true) || ""),
                getNetUnit(sheet) || "",
                getRatePerUnit(sheet) || "",
                getLoadSubTotal(sheet, true) || "",
                (sheet.fuelRate || sheet.fuelRate === 0) ? `${sheet.fuelRate}%` : "",
                getFuelAmount(sheet, true) || ""
            ])),
            theme: 'striped',
            styles: {
                fontSize: 6,
                lineColor: [0, 0, 0],  // Border color
                lineWidth: 0.2         // Border width
            },
           
            tableLineColor: [0, 0, 0],  // Outer border color
            tableLineWidth: 0.2         // Outer border width
        });
    
        // Add content below the table
        let finalY = doc.previousAutoTable.finalY + 10;
        const pageHeight = doc.internal.pageSize.getHeight();
    
        if (finalY + 30 > pageHeight) {
            doc.addPage();
            finalY = 20; // Reset finalY for new page
        }
    
        doc.setFontSize(11);
        doc.setFont('times', 'normal');
        doc.text(`INVOICE SUBTOTAL: $${globalInvoiceSubTotal}`, doc.internal.pageSize.getWidth()-90, finalY);
        doc.text(`FSC: $${globalFSCTotal}`, doc.internal.pageSize.getWidth()-90, finalY + 6);
        doc.text(`INVOICE NET: $${globalInvoiceNet}`, doc.internal.pageSize.getWidth()-90, finalY + 12);
        doc.text(`HST (13%): $${globalHSTTotal}`, doc.internal.pageSize.getWidth()-90, finalY + 18);
        doc.setFont('times', 'bold')
        doc.text(`INVOICE TOTAL: $${globalInvoiceTotal}`, doc.internal.pageSize.getWidth()-90, finalY + 24);
    
        doc.setFontSize(9);
        doc.setFont('helvetica', 'normal');
        doc.text('We appreciate the business and continued support from our Vendors.', (doc.internal.pageSize.getWidth() / 2)-40, finalY + 10, { align: 'center' });
        doc.text('Please make cheques payable to Triple Trans Mill Services Ltd.', (doc.internal.pageSize.getWidth() / 2)-40, finalY + 15, { align: 'center' });
        doc.text('HST# 81131 4590 RT0001', (doc.internal.pageSize.getWidth() / 2)-40, finalY + 25, { align: 'center' });
    
        // Add the footer strip
        const stripY = finalY + 35;
        const stripHeight = 5;
    
        if (stripY + stripHeight > pageHeight) {
            doc.addPage();
            doc.setFillColor(169, 169, 169); // Grey color
            doc.rect(10, 20 - stripHeight / 2, doc.internal.pageSize.getWidth() - 20, stripHeight, 'F');
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(0, 0, 0); // Black color for text
            doc.text('THANK YOU FOR YOUR BUSINESS', doc.internal.pageSize.getWidth() / 2, 20 + 1, { align: 'center' });
        } else {
            doc.setFillColor(169, 169, 169); // Grey color
            doc.rect(10, stripY - stripHeight / 2, doc.internal.pageSize.getWidth() - 20, stripHeight, 'F');
            doc.setFontSize(9);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(0, 0, 0); // Black color for text
            doc.text('THANK YOU FOR YOUR BUSINESS', doc.internal.pageSize.getWidth() / 2, stripY + 1, { align: 'center' });
        }
    
        const filename = (globalPartnerName) ? ('customer_invoice_' + (globalPartnerName?globalPartnerName:"") + '.pdf') : "customer_invoice.pdf";
        doc.save(filename);
    };

    const downloadImagesPDF = async () => {
        setValues({ ...values, loadingModal: true });
        handleShowLoadingModal();
    
        const doc = new jsPDF('landscape');
        let startY = 5;
        let hasImage = false;
        let countOfImages = 0;
    
        // Fetch S3 images
        const fetchImagePromises = [];
        const imagesData = [];
        for (const [index, tripSheet] of tripSheets.entries()) {
            if (isS3IntegrationEnabled) {
                const imageFields = ['photo1Name', 'photo2Name', 'photo3Name', 'photo4Name', 'photo5Name', 'photo6Name'];
                imageFields.forEach((field, i) => {
                    if (tripSheet && tripSheet[field]) {
                        const promise = fetchImageFromS3(tripSheet._id, `tripPhoto${i + 1}`).then(async (url) => {
                            const data = await fetchAndCacheImage(url);
                            imagesData.push({data, tripsheet: index+1, count: i+1});
                        });
                        fetchImagePromises.push(promise);
                    }
                });
            }
    
            // Fetch MongoDB images
            if (isMongoDBImageReadEnabled) {
                for (let i = 1; i <= 6; i++) {
                    const photo = tripSheet[`photo${i}`];
                    if (photo && photo.data) {
                        const imgData = `data:${photo.contentType};base64,${photo.data.toString('base64')}`;
                        imagesData.push({data: imgData, tripsheet: index+1, count: i+1});
                    }
                }
            }
        }
    
        await Promise.all(fetchImagePromises);
    
        // Add images to the PDF
        if (imagesData.length > 0) {
            hasImage = true;
            countOfImages += imagesData.length;

            // Group images by trip sheet and sort them by trip sheet and count
            const groupedImages = imagesData.reduce((acc, imageData) => {
                const { tripsheet, count } = imageData;
                if (!acc[tripsheet]) {
                    acc[tripsheet] = [];
                }
                acc[tripsheet].push(imageData);
                return acc;
            }, {});

            // Sort the grouped images by trip sheet number
            const sortedTripSheets = Object.keys(groupedImages).sort((a, b) => a - b);

            for (const tripSheet of sortedTripSheets) {
                const images = groupedImages[tripSheet];
                // Sort images within each trip sheet by count
                images.sort((a, b) => a.count - b.count);
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text(`Trip Sheet ${tripSheet}`, 10, startY);
                startY += 5;
        
                for (const [j, imageData] of images.entries()) {
                    const imgProps = doc.getImageProperties(imageData.data);
                    const pageWidth = doc.internal.pageSize.getWidth();
                    const pageHeight = doc.internal.pageSize.getHeight();
                    const imgMaxWidth = pageWidth * 0.88;
                    const imgMaxHeight = pageHeight * 0.88;
        
                    let imgWidth = imgMaxWidth;
                    let imgHeight = (imgProps.height * imgWidth) / imgProps.width;
        
                    if (imgHeight > imgMaxHeight) {
                           imgHeight = imgMaxHeight;
                           imgWidth = (imgProps.width * imgHeight) / imgProps.height;
                    }
        
                    const xPosition = (pageWidth - imgWidth) / 2;
                    const yPosition = startY + ((pageHeight - startY - imgHeight) / 2);
        
                    doc.text(`Image: ${j+1}`, 10, startY);
                    doc.addImage(imageData.data, 'JPEG', xPosition, yPosition, imgWidth, imgHeight);
        
                    doc.addPage();
                    startY = 5;
                }
            }
        }
            
        const lastPageNumber = doc.internal.getNumberOfPages();
        if (lastPageNumber > countOfImages) {
            doc.deletePage(doc.internal.getNumberOfPages());
        }
    
        hideLoadingModal();
        setValues({ ...values, loadingModal: false });
    
        if (!hasImage) {
            toastifyMessage("warning", "No images exist for the customer invoice");
            return;
        }
    
        const filename = globalPartnerName
            ? `invoice_photos_${globalPartnerName}_${moment(startDate).format('DDMMYYYY')}_${moment(endDate).format('DDMMYYYY')}.pdf`
            : `invoice_photos_${moment(startDate).format('DDMMYYYY')}_${moment(endDate).format('DDMMYYYY')}.pdf`;
        doc.save(filename);

        // Clear Image cache
        clearImageCache();
    };

    const header = [
        'Sr. No.', 'Shipping Date', 'Shipping Location', 'Ticket Number', 'Net Pounds', 
        'Receiving Date', 'Receiving Location', 'Ticket Number', 'Net Pounds', 
        'Commodity','Truck Number', 'Trailer Number', 'Customer', 'Driver', 'Billable Wait Time (mins)', 'Wait Charge ($/hour)', 'Billable Wait Charge',
        'Net Unit', 'Rate Per Unit', 'Load Subtotal', 'Fuel S/C Rate', 'Fuel Amount'
    ];
    
    const body = tripSheets.map((sheet, index) => ([
        index + 1,
        sheet.date ? getDate(sheet.date) : "",
        getPickupLocation(sheet) || "",
        sheet.ticketNumberAtShipping ? sheet.ticketNumberAtShipping : "",
        (sheet.inBoundWeight || sheet.inBoundWeight === 0) ? sheet.inBoundWeight : "",
        (sheet.typeOfLoad && (sheet.typeOfLoad===311 || sheet.typeOfLoad===321)) ? "-" : (sheet.receivingDate ? getDate(sheet.receivingDate) : ""),
        (sheet.typeOfLoad && (sheet.typeOfLoad===311 || sheet.typeOfLoad===321)) ? "-" : (getDeliveryLocation(sheet) || ""),
        (sheet.typeOfLoad && (sheet.typeOfLoad===311 || sheet.typeOfLoad===321)) ? "-" : (sheet.ticketNumberAtReceiving ? sheet.ticketNumberAtReceiving : ""),
        (sheet.typeOfLoad && (sheet.typeOfLoad===311 || sheet.typeOfLoad===321)) ? "-" : ((sheet.outBoundWeight || sheet.outBoundWeight === 0) ? sheet.outBoundWeight : ""),
        getPickupCommodity(sheet, true) || "",
        getTruckNo_2(sheet, true) || "",
        getTrailerNo_2(sheet, true) || "",
        getPickupCompany_2(sheet, true) || "",
        getDriverName(sheet, true) || "",
        sheet.isWaitingTimeWaivedOff ? "0" :(getBillableWaitingPeriod(sheet) || ""),
        getWaitRate(sheet, true) || "",
        sheet.isWaitingTimeWaivedOff ? "$0.00" :(getBillableWaitCharge(sheet, true) || ""),
        getNetUnit(sheet) || "",
        getRatePerUnit(sheet) || "",
        getLoadSubTotal(sheet, true) || "",
        (sheet.fuelRate || sheet.fuelRate === 0) ? `${sheet.fuelRate}%` : "",
        getFuelAmount(sheet, true) || ""
    ]));

    const handleDownloadExcel = () => {
      const excelFilename = globalPartnerName ? `customer_invoice_${globalPartnerName}` : "customer_invoice";
      downloadExcel({
        fileName: excelFilename,
        sheet: "invoice",
        tablePayload: {
          header: header,
          body: body
        },
      });
    };

    const exportToExcel = () => {
        const ws = XLSX.utils.aoa_to_sheet([header, ...body]);
      
        // Calculate column widths based on content length
        const colWidths = header.map((col, i) => {
          // Initialize with the width of the header
          let max = col.length;
      
          // Check each cell in the column (including header)
          body.forEach(row => {
            const cellValue = row[i] ? row[i].toString() : "";
            if (cellValue.length > max) {
              max = cellValue.length;
            }
          });
      
          // Add extra width to ensure the content is not cut off
          return { wch: max + 2 };
        });
      
        // Set column widths in the worksheet
        ws['!cols'] = colWidths;
      
        // Add 4 empty rows
        const totalRows = body.length + 1; // +1 for the header
        for (let i = 0; i < 4; i++) {
          const rowIndex = totalRows + i;
          XLSX.utils.sheet_add_aoa(ws, [[]], { origin: `A${rowIndex + 1}` });
        }
      
        // Add the additional rows in columns C and D
        const additionalRows = [
          ["INVOICE SUBTOTAL", "$" + globalInvoiceSubTotal],
          ["FSC", "$" + globalFSCTotal],
          ["INVOICE NET", "$" + globalInvoiceNet],
          ["HST (13%)", "$" + globalHSTTotal],
          ["INVOICE TOTAL", "$" + globalInvoiceTotal]
        ];

        XLSX.utils.sheet_add_aoa(ws, additionalRows, { origin: `C${totalRows + 5}` });
      
        // Create a new workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "invoice");
      
        // Generate the Excel file
        const excelFilename = globalPartnerName ? `customer_invoice_${globalPartnerName}.xlsx` : "customer_invoice.xlsx";
        XLSX.writeFile(wb, excelFilename);
      };
    
    const dateFilterForm = () => (
        <div className="date-filter-form">
            <div className="date-filter-row">
                <label className="text-light">Start Date</label>
                <input
                    onChange={handleChange('startDate')}
                    type="date"
                    className="form-control date-input"
                    value={startDate}
                    max={endDate}
                />
                <label className="text-light">End Date</label>
                <input
                    onChange={handleChange('endDate')}
                    type="date"
                    className="form-control date-input"
                    value={endDate}
                    min={startDate}
                />
                <button onClick={onSubmit} className="btn btn-outline-info submit-btn rounded">
                    Submit
                </button>
                <button
                    onClick={downloadPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download as PDF
                </button>
                <button
                    onClick={exportToExcel}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download as XLSX
                </button>
                <button
                    onClick={downloadImagesPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Photos
                </button>
            </div>
        </div>
    );

    // For loading modal
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const hideLoadingModal = () => setShowLoadingModal(false);
    const handleShowLoadingModal = () => setShowLoadingModal(true);

    return (
        <Base title="Invoice Dashboard" description="View/Edit">
            <Link className="btn btn-info rounded" to={`/admin/dashboard`}>
                <span className="">Admin Home</span>
            </Link>
            <div className="invoice-dashboard">
                <div className="invoice-header">
                    <h2 className="text-center text-success my-3 mt-5 mb-5" style={{ fontFamily: 'Englebert' }}>View Invoice</h2>
                    {dateFilterForm()}
                </div>
                <div className="invoice-content">
                    {error && <h4 className="error-message">Error fetching the loads</h4>}
                    {isLoaded && !error && tripSheets.length > 0 && (
                        <>
                            <div className="filters">
                                {isTextDropdownFilterEnabled ?
                                    <>
                                        <div className="filter-item">
                                            <FilterDropdown
                                                label={"Filter the loads using location"}
                                                placeholder={"Location"}
                                                options={locationOptions}
                                                value={locationOptions.find((option) => option.value === filters.location)}
                                                onChange={(selectedOption) => handleDropDownFilterChange('location', selectedOption)}
                                            />
                                        </div>
                                        <div className="filter-item">
                                            <FilterDropdown
                                                label={"Filter the loads using commodity"}
                                                placeholder={"Commodity"}
                                                options={commodityOptions}
                                                value={commodityOptions.find((option) => option.value === filters.commodity)}
                                                onChange={(selectedOption) => handleDropDownFilterChange('commodity', selectedOption)}
                                            />
                                        </div>
                                        <div className="filter-item">
                                             <p style={{ marginBottom: '10px' }}>Filter using Ticket #</p>
                                             <input
                                                 type="text"
                                                 style={{
                                                    width: '100%',
                                                    maxWidth: '300px',
                                                    padding: '8px',
                                                    boxSizing: 'border-box'
                                                  }}
                                                 placeholder="Filter the loads using the ticket #"
                                                 name="ticketNumber"
                                                 value={filters.ticketNumber}
                                                 onChange={handleLoadSearch}
                                             />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="filter-item">
                                             <p style={{ marginBottom: '10px' }}>Filter using Location:</p>
                                             <input
                                                 type="text"
                                                 style={{
                                                    width: '100%',
                                                    maxWidth: '300px',
                                                    padding: '8px',
                                                    boxSizing: 'border-box'
                                                  }}
                                                 placeholder="Filter the loads using the location"
                                                 name="location"
                                                 value={filters.location}
                                                 onChange={handleLoadSearch}
                                             />
                                        </div>
                                        <div className="filter-item">
                                             <p style={{ marginBottom: '10px' }}>Filter using Commodity:</p>
                                             <input
                                                 type="text"
                                                 style={{
                                                    width: '100%',
                                                    maxWidth: '300px',
                                                    padding: '8px',
                                                    boxSizing: 'border-box'
                                                  }}
                                                 placeholder="Filter the loads using the commodity name"
                                                 name="commodity"
                                                 value={filters.commodity}
                                                 onChange={handleLoadSearch}
                                             />
                                        </div>
                                        <div className="filter-item">
                                             <p style={{ marginBottom: '10px' }}>Filter using Ticket #:</p>
                                             <input
                                                 type="text"
                                                 style={{
                                                    width: '100%',
                                                    maxWidth: '300px',
                                                    padding: '8px',
                                                    boxSizing: 'border-box'
                                                  }}
                                                 placeholder="Filter the loads using the ticket #"
                                                 name="ticketNumber"
                                                 value={filters.ticketNumber}
                                                 onChange={handleLoadSearch}
                                             />
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="invoice-table-container">
                                <InvoiceTableView tripSheets={filteredLoads} editURL={`/admin/update/partner/tripsheet/`} isAggregated={true} isAdmin={true} showPhotos={true} updateSheet={updateInvoice} flipSheetStatus={flipSheetStatus} reloadSheet={reloadInvoice}/>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <PreparingDownload showModal={showLoadingModal} hideModal={hideLoadingModal}  isLoading={loadingModal} />
            {LoadingScreen(loading)}
            <ToastifyMessage />
        </Base>
    );
}