import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import Base from '../../core/Base';
import { isAuthenticated } from '../../auth/helper/adminIndex';
import { getallfuelsheets, deletefuellogsheet, fetchImageFromS3 } from '../helper/adminapicall';
import DeleteConfirmation from '../../core/helper/DeleteConfirmation';
import PreparingDownload from '../../core/helper/PreparingDownload';
import LoadingScreen from '../../core/helper/LoadingScreen';
import FuelLogSheetTableView from '../../core/helper/FuelLogSheetTableView';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import { toastifyMessage } from '../../core/helper/Toast';
import jsPDF from 'jspdf';
import '../AdminSideSheet.css';
import { getTruckNo_3, getCreditCard, getDate, getSheetDriverName } from '../../core/helper/helperFunctions';
import { S3_INTEGRATION_ENABLED, ENABLE_MONGO_READ_IMAGE } from "../../backend";
import { fetchAndCacheImage, clearImageCache } from '../../core/helper/Utils/DownloadUtils';

export default function ViewFuelSheets() {
    const { admin, token } = isAuthenticated();

    // Kill switch based implementation for S3
    const s3_integration_enabled_prop = (S3_INTEGRATION_ENABLED).toString().toLowerCase();
    const isS3IntegrationEnabled = s3_integration_enabled_prop==="true" ? true : false;

    // Kill switch to disable images reads from mongo database
    const enable_mongo_read_image_prop = (ENABLE_MONGO_READ_IMAGE).toString().toLowerCase();
    const isMongoDBImageReadEnabled = enable_mongo_read_image_prop==="true" ? true : false;

    const [values, setValues] = useState({
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loading: "",
        loadingModal: "",
        error: "",
        isLoaded: "",
        fuelSheets: []
    });

    const { startDate, endDate, error, loading, loadingModal, isLoaded, fuelSheets } = values;

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: "" });
        getallfuelsheets(admin._id, { startDate, endDate }, token).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: "", isLoaded: "" });
                toastifyMessage("error", "Error fetching the fuel sheets");
            } else {
                const updatedValues = {
                    ...values,
                    fuelSheets: data,
                    loading: "",
                    isLoaded: true
                };

                setValues(updatedValues);
                if (data.length === 0) {
                    toastifyMessage("warning", "No fuel sheet found");
                }
            }
        })
        .catch(err => {
            console.log('Error loading the fuel sheets:', err);
            setValues({ ...values, error: "Error loading the fuel sheets", loading: "" });
            toastifyMessage("error", 'Error loading the fuel sheets');
        });
    };

    useEffect(() => {
        preload();
    }, []);

    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, [name]: value });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        preload();
    };

    const downloadPDF = () => {
        const doc = new jsPDF('landscape');
        const systemDate = new Date();
        const pageHeight = doc.internal.pageSize.getHeight();

        // Add content above the image
        doc.setFontSize(15);
        doc.setFont('helvetica', 'bold');
        doc.text(`Fuel Sheet`, doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });

        doc.setFontSize(11);
        doc.setFont('helvetica', 'bold');
        doc.text(`Date:`, 10, 18);
        doc.text(`Report Duration:`, 10, 23);

        doc.setFont('helvetica', 'normal');
        doc.text(`${moment(systemDate).format('MMMM D, YYYY')}`, 22, 18);
        doc.text(`${moment(startDate).format('MMMM D, YYYY')} to ${moment(endDate).format('MMMM D, YYYY')}`, 43, 23);

        const tableColumns = ["Sr. No.", "Refuelling Date", "Driver", "Card Number", "Truck",  "Odometer", "DEF (in Litres)", "Fuel (in Litres)", "Location"];

        const tableRows = fuelSheets.map((fuelSheet, index) => {
            const row = [
                index + 1,
                fuelSheet.refuellingDate ? getDate(fuelSheet.refuellingDate) : "" || "",
                getSheetDriverName(fuelSheet, true) || "",
                getCreditCard(fuelSheet, true),
                getTruckNo_3(fuelSheet, true),
                (fuelSheet.odometer || fuelSheet.odometer===0) ? fuelSheet.odometer : "",
                (fuelSheet.def || fuelSheet.def===0) ? fuelSheet.def : "",
                (fuelSheet.fuel || fuelSheet.fuel===0) ? fuelSheet.fuel : "",
                fuelSheet.location || "",
            ];
            return row;
        });

        doc.autoTable({
            startY: 35,
            head: [tableColumns],
            body: tableRows,
            theme: 'striped',
            styles: {
                fontSize: 9,
                lineColor: [0, 0, 0],  // Border color
                lineWidth: 0.2         // Border width
            },
           
            tableLineColor: [0, 0, 0],  // Outer border color
            tableLineWidth: 0.2         // Outer border width
        });

        const filename = `Fuel_sheet_${moment(startDate).format('DDMMYYYY')}_${moment(endDate).format('DDMMYYYY')}.pdf`;
        doc.save(filename);
    };

    const downloadImagesPDF = async () => {
        setValues({ ...values, loadingModal: true });
        handleShowLoadingModal();
    
        const doc = new jsPDF('landscape');
        let startY = 5;
        let hasImage = false;
        let countOfImages = 0;
    
        // Fetch S3 images
        const fetchImagePromises = [];
        const imagesData = [];
        for (const [index, fuelSheet] of fuelSheets.entries()) {
            if (isS3IntegrationEnabled) {
                const imageFields = ['fuelPhoto1Name', 'fuelPhoto2Name'];
                imageFields.forEach((field, i) => {
                    if (fuelSheet && fuelSheet[field]) {
                        const promise = fetchImageFromS3(fuelSheet._id, `fuelPhoto${i + 1}`).then(async (url) => {
                            const data = await fetchAndCacheImage(url);
                            imagesData.push({data, fuelsheet: index+1, count: i+1});
                        });
                        fetchImagePromises.push(promise);
                    }
                });
            }
    
            // Fetch MongoDB images
            if (isMongoDBImageReadEnabled) {
                for (let i = 1; i <= 4; i++) {
                    const photo = fuelSheet[`photo${i}`];
                    if (photo && photo.data) {
                        const imgData = `data:${photo.contentType};base64,${photo.data.toString('base64')}`;
                        imagesData.push({data: imgData, fuelsheet: index+1, count: i+1});
                    }
                }
            }
        }
    
        await Promise.all(fetchImagePromises);
    
        // Add images to the PDF
        if (imagesData.length > 0) {
            hasImage = true;
            countOfImages += imagesData.length;

            // Group images by fuel sheet and sort them by fuel sheet and count
            const groupedImages = imagesData.reduce((acc, imageData) => {
                const { fuelsheet, count } = imageData;
                if (!acc[fuelsheet]) {
                    acc[fuelsheet] = [];
                }
                acc[fuelsheet].push(imageData);
                return acc;
            }, {});

            // Sort the grouped images by fuel sheet number
            const sortedFuelSheets = Object.keys(groupedImages).sort((a, b) => a - b);

            for (const fuelsheet of sortedFuelSheets) {
                const images = groupedImages[fuelsheet];
                // Sort images within each fuel sheet by count
                images.sort((a, b) => a.count - b.count);
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text(`Fuel Sheet ${fuelsheet}`, 10, startY);
                startY += 5;

                for (const [j, imageData] of images.entries()) {
                    const imgProps = doc.getImageProperties(imageData.data);
                    const pageWidth = doc.internal.pageSize.getWidth();
                    const pageHeight = doc.internal.pageSize.getHeight();
                    const imgMaxWidth = pageWidth * 0.88;
                    const imgMaxHeight = pageHeight * 0.88;

                    let imgWidth = imgMaxWidth;
                    let imgHeight = (imgProps.height * imgWidth) / imgProps.width;

                    if (imgHeight > imgMaxHeight) {
                        imgHeight = imgMaxHeight;
                        imgWidth = (imgProps.width * imgHeight) / imgProps.height;
                    }

                    const xPosition = (pageWidth - imgWidth) / 2;
                    const yPosition = startY + ((pageHeight - startY - imgHeight) / 2);

                    doc.text(`Image: ${j+1}`, 10, startY);
                    doc.addImage(imageData.data, 'JPEG', xPosition, yPosition, imgWidth, imgHeight);

                    doc.addPage();
                    startY = 5;
                }
            }
        }
        
        const lastPageNumber = doc.internal.getNumberOfPages();
        if (lastPageNumber > countOfImages) {
            doc.deletePage(doc.internal.getNumberOfPages());
        }
    
        hideLoadingModal();
        setValues({ ...values, loadingModal: false });
    
        if (!hasImage) {
            toastifyMessage("warning", "No images exist for the fuel sheet");
            return;
        }
    
        const filename = `Fuelsheet_photos_${moment(startDate).format('DDMMYYYY')}_${moment(endDate).format('DDMMYYYY')}.pdf`;
        doc.save(filename);
                
        // Clear Image cache
        clearImageCache();
    };
    
    const dateFilterForm = () => (
        <div className="date-filter-form">
            <div className="date-filter-row">
                <label className="text-light">Start Date</label>
                <input
                    onChange={handleChange('startDate')}
                    type="date"
                    className="form-control date-input"
                    value={startDate}
                    max={endDate}
                />
                <label className="text-light">End Date</label>
                <input
                    onChange={handleChange('endDate')}
                    type="date"
                    className="form-control date-input"
                    value={endDate}
                    min={startDate}
                />
                <button onClick={onSubmit} className="btn btn-outline-info submit-btn rounded">
                    Submit
                </button>
                <button
                    onClick={downloadPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Sheet
                </button>
                <button
                    onClick={downloadImagesPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download Photos
                </button>
            </div>
        </div>
    );

    // Only required for Admin
    // For fuel sheet table
    // For Delete confirmation modal
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("Are you sure you want to delete the fuel sheet?");
    const [deleteFuelSheetId, setDeleteFuelSheetId] = useState("");
    const handleCancelDeletion = () => setShowDeleteMessage(false);
    const handleShowDeleteMessage = () => setShowDeleteMessage(true);

    // Delete Fuel Log Sheet using the Admin privilege
    const deleteThisFuelSheet = (fuelLogSheetId) => {
        setValues({ ...values, error: "", loadingModal: true});
        deletefuellogsheet(fuelLogSheetId, admin._id, token)
          .then(data=> {
              if(data.error){
                setValues({ ...values, error: data, loadingModal: "" })
                toastifyMessage("error", "Error deleting the fuel sheet");
              }
              else{
                toastifyMessage("success", "Fuel Sheet deleted successfully");

                // To close the modal & Reload the values
                handleCancelDeletion();
                setValues({ ...values, error: "", loadingModal: "", fuelSheets: (fuelSheets.filter(sheet => (sheet._id !== fuelLogSheetId)))})            
              }
          })
          .catch(err => {
            console.log('Error deleting the fuel sheet:', err);
            setValues({ ...values, error: "Error deleting the fuel sheet", loadingModal: "" });
            toastifyMessage("error", 'Error deleting the fuel sheet');
        });
    }

    // For loading modal
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const hideLoadingModal = () => setShowLoadingModal(false);
    const handleShowLoadingModal = () => setShowLoadingModal(true);

    return (
        <Base title="Fuel Sheet Dashboard" description="View/Edit/Delete">
            <Link className="btn btn-info rounded" to={`/admin/dashboard`}>
                <span className="">Admin Home</span>
            </Link>
            <div className="fuel-log-sheet-dashboard">
                <div className="fuel-log-sheet-header">
                <h2 className="text-center text-success my-3 mt-5 mb-5" style={{ fontFamily: 'Englebert' }}>View Fuel Sheets</h2>
                    {dateFilterForm()}
                </div>
                    
                <div className="invoice-content">
                    {error && <h4 className="error-message">Error fetching the fuel sheets</h4>}
                    {isLoaded && !error && fuelSheets.length > 0 && (  
                        <div id="fuel-log-sheet-table">
                            <FuelLogSheetTableView
                                fuelSheets={fuelSheets}
                                editURL={`/admin/update/driver/fuellogsheet/`}
                                isAggregated={true}
                                isAdmin={true} isDriverNameRequired={true}
                                setDeleteFuelSheetId={setDeleteFuelSheetId}
                                handleShowDeleteMessage={handleShowDeleteMessage}
                            />
                        </div>
                    )}
                </div>
            </div>
            {LoadingScreen(loading)}
            <PreparingDownload showModal={showLoadingModal} hideModal={hideLoadingModal}  isLoading={loadingModal} />
            <DeleteConfirmation showModal={showDeleteMessage} hideModal={handleCancelDeletion} confirmDeleteModal={deleteThisFuelSheet} id={deleteFuelSheetId} message={deleteMessage} loadingModal={loadingModal} />
            <ToastifyMessage />
        </Base>
    )
}