import React, { useState, useEffect } from 'react';
import Base from "../../core/Base";
import { disAuthenticated } from '../../auth/helper/driverIndex';
import { getFuelLogSheetsForDriver } from '../helper/driverapicall';
import LoadingScreen from '../../core/helper/LoadingScreen';
import FuelLogSheetTableView from '../../core/helper/FuelLogSheetTableView';
import moment from 'moment';
import ToastifyMessage from '../../core/helper/ToastifyMessage';
import {toastifyMessage} from '../../core/helper/Toast';
import jsPDF from 'jspdf';
import '../DriverSideSheet.css';
import { getTruckNo_3, getCreditCard, getDate } from '../../core/helper/helperFunctions';

// Total Metrics
let globalFuelTotal = 0;
let globalDefTotal = 0;
let globalDistanceTotal = 0;

export default function ManageFuelLogSheets() {
    const { driver, token } = disAuthenticated();

    const [values, setValues] = useState({
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loading: "",
        error: "",
        isLoaded: "",
        fuelSheets: [],
        firstName: driver && driver.firstName ? driver.firstName : "",
        lastName: driver && driver.lastName ? driver.lastName : ""
    });

    const { startDate, endDate, loading, error, isLoaded, fuelSheets, firstName, lastName } = values;

    const preload = () => {
        setValues({ ...values, loading: true, isLoaded: "" });
        getFuelLogSheetsForDriver(driver._id, { startDate, endDate }, token).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: "", isLoaded: "" });
            } else {
                setValues(values => ({ ...values, fuelSheets: data, loading: "", isLoaded: true }));
                if(data.length===0){
                    toastifyMessage("warning", 'No fuel sheet found');
                }

                // Total calculations
                globalFuelTotal = (data.reduce((total, sheet) => total + parseFloat(sheet.fuel || 0), 0)).toFixed(2);
                globalDefTotal = (data.reduce((total, sheet) => total + parseFloat(sheet.def || 0), 0)).toFixed(2);
            }
        })
        .catch(err => {
            console.log('Error loading the fuel sheets', err);
            setValues({ ...values, error: "Error loading the fuel sheets", loading: "" });
            toastifyMessage("error", 'Error loading the fuel sheets');
          });
    };

    useEffect(() => {
        preload();
    }, []);

    const reCalculateTotals = () => {
        globalFuelTotal = (fuelSheets.reduce((total, sheet) => total + parseFloat(sheet.fuel || 0), 0)).toFixed(2);
        globalDefTotal = (fuelSheets.reduce((total, sheet) => total + parseFloat(sheet.def || 0), 0)).toFixed(2);               
    }
    
     // Recalculate totals whenever fuelsheets state changes
     useEffect(() => {
        reCalculateTotals();
    }, [values.fuelSheets]);

    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, [name]: value });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        preload();
    };

    const downloadPDF = () => {
        const doc = new jsPDF('landscape');
        const pageHeight = doc.internal.pageSize.getHeight();

        // Add content above the image
        doc.setFontSize(15);
        doc.setFont('helvetica', 'bold');
        doc.text(`Fuel Sheet`, doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });

        doc.setFontSize(11);
        doc.setFont('helvetica', 'bold');
        doc.text(`Driver:`, 10, 18);
        doc.text(`Report Duration:`, 10, 23);

        doc.setFont('helvetica', 'normal');
        doc.text(`${firstName} ${lastName}`, 24, 18);
        doc.text(`${moment(startDate).format('MMMM D, YYYY')} to ${moment(endDate).format('MMMM D, YYYY')}`, 43, 23);

        const tableColumns = ["Sr. No.", "Refuelling Date", "Card Number", "Truck",  "Odometer", "DEF (in Litres)", "Fuel (in Litres)", "Location"];

        const tableRows = fuelSheets.map((fuelSheet, index) => {
            const row = [
                index + 1,
                fuelSheet.refuellingDate ? getDate(fuelSheet.refuellingDate) : "" || "",
                getCreditCard(fuelSheet, true),
                getTruckNo_3(fuelSheet, true),
                (fuelSheet.odometer || fuelSheet.odometer===0) ? fuelSheet.odometer : "",
                (fuelSheet.def || fuelSheet.def===0) ? fuelSheet.def : "",
                (fuelSheet.fuel || fuelSheet.fuel===0) ? fuelSheet.fuel : "",
                fuelSheet.location || "",

            ];
            return row;
        });

        doc.autoTable({
            startY: 35,
            head: [tableColumns],
            body: tableRows,
            theme: 'striped',
            styles: {
                fontSize: 9,
                lineColor: [0, 0, 0],  // Border color
                lineWidth: 0.2         // Border width
            },
           
            tableLineColor: [0, 0, 0],  // Outer border color
            tableLineWidth: 0.2         // Outer border width
        });

        // Add content below the table
        let finalY = doc.previousAutoTable.finalY + 10;
        const pageHt = doc.internal.pageSize.getHeight();
    
        if (finalY + 20 > pageHt) {
            doc.addPage();
            finalY = 20;
        }

        doc.setFontSize(11);
        doc.setFont('times', 'bold')
        doc.text(`Fuel Total (in Litres): ${globalFuelTotal}`, doc.internal.pageSize.getWidth()-75, finalY);
        doc.text(`DEF Total (in Litres): ${globalDefTotal}`, doc.internal.pageSize.getWidth()-75, finalY+6);

        const filename = (firstName && lastName) 
        ? `Fuel_sheet_${firstName}_${lastName}_${moment(startDate).format('DDMMYYYY')}_${moment(endDate).format('DDMMYYYY')}.pdf`
        : `Fuel_sheet_${moment(startDate).format('DDMMYYYY')}_${moment(endDate).format('DDMMYYYY')}.pdf`;
        doc.save(filename);
    };

    const dateFilterForm = () => (
        <div className="date-filter-form">
            <div className="date-filter-row">
                <label className="text-light">Start Date</label>
                <input
                    onChange={handleChange('startDate')}
                    type="date"
                    className="form-control date-input"
                    value={startDate}
                    max={endDate}
                />
                <label className="text-light">End Date</label>
                <input
                    onChange={handleChange('endDate')}
                    type="date"
                    className="form-control date-input"
                    value={endDate}
                    min={startDate}
                />
                <button onClick={onSubmit} className="btn btn-outline-info submit-btn rounded">
                    Submit
                </button>
                <button
                    onClick={downloadPDF}
                    className="btn btn-outline-success download-btn rounded"
                    style={{
                        borderColor: 'green',
                        background: 'green',
                        color: 'white',
                    }}
                >
                    Download
                </button>
            </div>
        </div>
    );

    return (
        <Base title="Fuel Log Sheet Dashboard" description="Manage/Edit the fuel sheets">
            <div className="fuel-log-sheet-dashboard">
                <div className="fuel-log-sheet-header">
                    <h2 className="text-center text-success my-3 mt-5 mb-5">Fuel Log Sheets</h2>
                </div>
                <div>
                    {dateFilterForm()}
                </div>
                <div id="fuel-log-sheet-table" className="fuel-log-sheet-table">
                    <FuelLogSheetTableView fuelSheets={fuelSheets} editURL={`/driver/update/fuellogsheet/`} isAggregated={true} isAdmin={false} isDriver={true} />
                </div>
            </div>
            {LoadingScreen(loading)}
            <ToastifyMessage />
        </Base>
    );
}